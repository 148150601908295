import { createContext } from 'react';
import usePublicBlogApi from './hook';

export const PublicBlogContext = createContext({
  t: () => {},
  user: {},
  fetchPostSingleData: () => {},
  postSingle: {},
  postSingleLoaded: false,
  fetchPublicBlogData: () => {},
  blogPostsData: [],
  blogPostsDataLoaded: false,
  blogPaginationData: {},
});

export const PublicBlogProvider = ({ children }) => {
  const value = usePublicBlogApi();
  return (
    <PublicBlogContext.Provider value={value}>
      {children}
    </PublicBlogContext.Provider>
  );
};
