import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';

const useFAQ = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const { authGet } = useApi();
  const [faqsData, setFAQsData] = useState([]);
  const [faqsDataLoaded, setFAQsDataLoaded] = useState(false);

  const getFAQsData = useCallback(
    async () => {
      try {
        setFAQsDataLoaded(false);
        const response = await authGet('/faq/active');
        setFAQsData(response.data || []);
      } catch (err) {
        console.log(err);
        toast.error('Error fetching faq Data');
      }
      setFAQsDataLoaded(true);
    },
    [authGet],
  );

  const [sortAsc, setSortAsc] = useState(true);

  const handleChangeSortDirection = useCallback(() => {
    setSortAsc((prev) => !prev);
  }, [setSortAsc, sortAsc]);

  useEffect(() => {
    getFAQsData();
  }, []);

  return {
    t,
    user,

    getFAQsData,

    faqsData,
    faqsDataLoaded,

    sortAsc,
    handleChangeSortDirection,
  };
};

export default useFAQ;
