/* eslint-disable max-len */
import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const MemeGamerChallengePage = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" className="memegamer-rules-page">
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          mt: 3,
        }}
        variant="h1"
      >
        <span className="primary-text-gradient">Challenge Guidelines</span>
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`Until Saturday, February 1st at 11:59pm GMT all Challenge packs will be specially priced at 50% down with the remaining 50% to be paid out of earnings 
          and will automatically include three consecutive challenge repurchases. For example, the 5 Star Founder package will continue to pay the $300 daily rank up bonus for a 30 day period but will now 
          be priced at $3,000 down with the remaining $3,000 to be paid out of the rank up bonus earnings including renewals for the next 3 consecutive months regardless of any Challenge Volume generated by personally sponsorship. 
          All matching bonuses will continue to be paid in full. This bonus promo will be open to all new sign ups in the challenge and challenge renewals. For the challenge renewals payment will be required over The bonus period and will be extend out from the current challenge closing date. So, if your current challenge is closing out on February 1st, this promo bonus will be added at the end of your current expiration so you will 
          not lose any time.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('1. Packages are only available to users that are both gamers and ambassadors')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`2. Gamers purchases 1 of 5 special challenge packs ranging from $1,000 to $6,000 in your Gamer dashboard 
          under coin packs - paying only half of the fee up front. For example, if you wish to purchase the $1,000 1 Star Founder Challenge, 
          the up front cost is $500.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`3. For each day a gamer plays they will receive a 
          limited introductory volume bonus in their ambassador team 1 and team 2 network ranging from $25,000 to $150,000.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('4. The challenge time frame is for 30 days from the date of the challenge pack purchase.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('5. First date of purchase is Dec 27 and the last day to purchase is Dec 31.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('6. You may purchase one of each level package during the promotional timeframe. The bonuses are not stacked and only the largest bonus would be applied.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`7. A matching bonus is applied daily for each direct sponsor you have who is also participating in the challenge and has played their 3 daily games.
          For detailed percentages of matching bonuses, please see the "challenge" page in the resources section of the back office.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`8. The second 50% of the cost of the pack is taken from your Ambassador wallet as soon as you have earned that mount 
          through your daily bonus and matching bonuses. For example, if I purchased the $1,000 Rank 1 Founder challenge pack 
          for $500, $500 will be deducted from my Ambassador wallet as soon as I have $500.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`9. A renewal pack of the challenge will be purchased from your Ambassador wallet as soon as you have earned that amount through your 
          daily bonus and matching bonuses and the second half of your original pack has been applied. For example, if I purchased the $1,000 Rank 1 
          Founder challenge pack, and have already had the second half of that pack's cost deducted from my Ambassador wallet, then once I have generated 
          another $1,000 in my Ambassador wallet, a second 1 Star Founder pack is purchased automatically, and will be applied to my account when my 
          first challenge pack expires after 30 days. The auto reserve pack Fullfillment applies to all challenges packs including all prior packs.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`10. You may not withdraw from your Ambassador wallet until the second half 
          of your pack and your renewal challenge pack have been deducted from your Ambassador wallet.`)}
      </Typography>

      <Grid sx={{ my: 3 }}>
        <Typography fontWeight={600}>
          {t('Package Options: ')}
        </Typography>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'lower-alpha',
          }}
        >
          <li>Level I: $1,000 and they receive 1,000 coins to play. Their $1,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $25,000 volume bonus will be awarded in their ambassador team 1 and team 2.</li>
          <li>Level II : $,2000 and they receive 2000 coins to play. Their $2000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $50,000 volume bonus will be awarded in their ambassador team 1 and team 2.</li>
          <li>Level III : $3,000 and they receive 3,000 coins to play. Their $3,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $75,000 volume bonus will be awarded in their ambassador team 1 and team 2.</li>
          <li>Level IV : $4,000 and they receive 4,000 coins to play. Their $4,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $100,000 volume bonus will be awarded in their ambassador team 1 and team 2.</li>
          <li>Level V : $6,000 and they receive 6,000 coins to play. Their $6,000 volume counts to upline ambassador sponsors. Each day they play the 100, 25 and 10 coin games a $150,000 volume bonus will be awarded in their ambassador team 1 and team 2.</li>
        </ul>
      </Grid>

      <Typography sx={{ my: 3 }}>
        {t('11. You may use and/or transfer your coins during your challenge enrollment period.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('12. All MG Coins are active for your 30 day period during your challenge enrollment, even if they are transferred to other gamers.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t('13. All daily pay and matching bonuses are paid each day at 12 midnight GMT to your Ambassador Wallet.')}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`14. The first $135 or 1,350 MG Tokens earned will be used for your reserve pack, to help you maintain 
          an active status after your challenge period. Your reserve pack will only be activated when you 
          are no longer active with a challenge pack.`)}
      </Typography>

      <Typography sx={{ my: 3 }}>
        {t(`15. All ambassadors enrolled in the challenge must personally sponsor 3 ambassadors at the same 
          or higher rank then themselves with at least 1 within their first 60 days, 2 total in first 90 
          days and 3 total over the 120 days.`)}
      </Typography>

      <Grid sx={{ my: 3 }}>
        <Typography fontWeight={600}>
          {t('Challenge payment Breakdown: ')}
        </Typography>

        <Typography fontWeight={600} style={{ marginBottom: '0' }}>
          {t('Month 1: ')}
        </Typography>
        <ul
          style={{
            marginLeft: '40px',
            color: 'white',
            listStyleType: 'decimal',
          }}
        >
          <li>50% down upfront (3kusd)</li>
          <li>50% balance (3kusd) paid out of earnings ($300 and $150 if applicable)</li>
          <li>$6,000 challenge reserve pack fro month 2 renewal</li>
        </ul>

        <Typography fontWeight={600} style={{ marginBottom: '0' }}>
          {t('Month 2: ')}
        </Typography>

        <Typography style={{ marginTop: '0', marginLeft: '20px' }}>
          {t('$6,000 challenge reserve pack since the previous reserve pack has now been used for month 2 renewal.')}
        </Typography>

        <Typography fontWeight={600} style={{ marginBottom: '0' }}>
          {t('Month 3: ')}
        </Typography>

        <Typography style={{ marginTop: '0', marginLeft: '20px' }}>
          {t('$6,000 challenge reserve pack since the previous reserve pack has now been used for month  3 renewal.')}
        </Typography>

        <Typography fontWeight={600} style={{ marginBottom: '0' }}>
          {t('Month 4: ')}
        </Typography>

        <Typography style={{ marginTop: '0', marginLeft: '20px' }}>
          {t(`$6,000 Legacy reserve pack (to continue your PV qualification for ongoing challenge volume to count 
            for your team volume and the opportunity to continue to enroll new challenges members) since the 
            previous reserve pack has now been used for month 4 renewal.`)}
        </Typography>

        <Typography fontWeight={600} style={{ marginBottom: '0' }}>
          {t('Month 5: ')}
        </Typography>

        <Typography style={{ marginTop: '0', marginLeft: '20px' }}>
          {t(`The $6,000 legacy reserves pack will need to be filled from your earnings but now the earnings will 
            come exclusively from the team volume generated under you as outlined in the Legacy comp plan. 
            If at the end of month 5 there are no earnings to fulfill the legacy reserve pack then the minimum 
            of the $135 reserve will be utilized to maintain active ambassador status through month 6.`)}
        </Typography>

        <Typography fontWeight={600} style={{ marginBottom: '0' }}>
          {t('Month 6: ')}
        </Typography>

        <Typography style={{ marginTop: '0', marginLeft: '20px' }}>
          {t(`Once you are out of the legacy reserve pack renewals you will no longer be able to receive 
            team volume from challenge members in your team including matching bonuses. However, if you 
            maintain the $135 reserve packs renewal you will be able to receive team volume on the 
            $135 mg coin pack sales and renewals.`)}
        </Typography>
      </Grid>

    </Container>
  );
};

export default MemeGamerChallengePage;
