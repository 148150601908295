/* eslint-disable import/prefer-default-export */
import { io } from 'socket.io-client';
import { createContext } from 'react';

const jwt = localStorage.getItem('token');

export const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
  // path: '/_mgws',
  withCredentials: true,
  extraHeaders: {
    authorization: `Bearer ${jwt}`,
    autoConnect: false,
  },
});

export const SocketContext = createContext(socket);
