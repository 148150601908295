/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import dayjs from 'dayjs';
import {
  useContext, useEffect,
  useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PersonRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Button from '../../../../components/Button';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import PaymentRenewModal from './components/PaymentRenewModal';
import RenewMembershipModal from './components/RenewMembershipModal';
import useApi from '../../../../utils/api';
import UploadProfileImageModal from './components/UploadProfileImageModal';
import compressImage from '../../../../utils/file';

export default function Welcome() {
  const { user, updateUserImage } = useContext(AuthenticationContext);
  const inputRef = useRef();
  const { t } = useTranslation();
  const { authGet } = useApi();
  const [image, setImage] = useState();
  const [currentImage, setCurrentImage] = useState(user?.image);
  const [renewPaymentData, setRenewPaymentData] = useState();
  const [renewMembershipData, setRenewMembershipData] = useState();
  const [selectedPackage, setSelectedPackage] = useState();

  const onRenew = async () => {
    try {
      // 1. Check if has passes available
      const availablePasses = await authGet('passes/available');
      // 2. Buy one with bitcoin
      if (availablePasses.length >= 1) {
        setRenewMembershipData({ user, availablePasses });
      } else {
        setRenewPaymentData({ user });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePaymentModalClose = () => {
    setRenewPaymentData();
  };

  const handleRenewModalClose = () => {
    setRenewMembershipData();
  };

  const onUserClick = () => {
    // reset the input value to allow the same file to be uploaded again
    inputRef.current.value = '';
    inputRef?.current?.click();
  };

  const onChangeImage = async (e) => {
    const file = e.target.files[0];
    const _compressed = await compressImage(file);
    setImage(_compressed);
  };

  const handleImageModalClose = () => {
    setImage();
  };

  useEffect(() => {
    if (user?.image) {
      setCurrentImage(user.image);
    }
  }, [user]);

  return (
    <div className="welcome-cont">
      <input onChange={onChangeImage} ref={inputRef} type="file" accept="image/png, image/jpeg, image/jpg, image/svg+xml, image/webp" hidden />

      <div className="icon-cont flex-row justify-center mb-6">
        {!currentImage ? <PersonRounded onClick={onUserClick} fontSize="large" style={{ cursor: 'pointer' }} />
          : <img src={currentImage} onClick={onUserClick} alt="Profile pic" className="profile-pic" />}
      </div>
      {image && (
        <UploadProfileImageModal
          image={image}
          user={user}
          handleModalClose={handleImageModalClose}
          updateUserImage={updateUserImage}
          setCurrentImage={setCurrentImage}
        />
      )}
      <p className="mb-6">{t('click on the image above to change it.')}</p>
      <h5 className="mb-2">{t('Welcome')}</h5>
      <h4 className="mb-2">{user.Name}</h4>
      <p className="mb-6">
        {t('Member since: ')}
        {dayjs(user.DateJoined).format('MMM DD, YYYY')}
      </p>
      {user?.isProduct && (
        <>
          <h5>{t('Activation Date:')}</h5>
          <h4 className="mb-4">{dayjs(user.DateLastRenewed).format('MMMM DD, YYYY')}</h4>
          <h5 className="mb-1">{t('Subscription Information:')}</h5>
          {/* <h4 className="mb-4">
            {user.isAffiliate && !user.isProduct ? '-'
              : dayjs(user.DateExpiring).format('MMMM DD, YYYY')}
          </h4> */}
          {(user.isProduct) ? (
            <>
              <Typography sx={{ color: 'var(--primary-color)', fontSize: '15px', mx: 'auto' }}>
                {user.Package}
              </Typography>
              <Typography sx={{ fontSize: '15px', mx: 'auto' }}>
                {`Your subscription expires in ${dayjs(user.DateExpiring).diff(dayjs(), 'day')} days`}
              </Typography>
              <Typography sx={{ fontSize: '15px', mx: 'auto', mb: 4 }}>
                {`Expiration Date: ${dayjs(user.DateExpiring).format('MMM DD, YYYY')}`}
              </Typography>
            </>
          ) : '-'}

          <div className="flex-row justify-center">
            <Button onClick={onRenew} bordered size="sm">
              {t('Activate Now')}
            </Button>
          </div>

          {renewPaymentData && (
            <PaymentRenewModal
              handleModalClose={handlePaymentModalClose}
              data={renewPaymentData}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
              location="profile"
            />
          )}

          {renewMembershipData && (
            <RenewMembershipModal
              handleModalClose={handleRenewModalClose}
              data={renewMembershipData}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
            />
          )}
        </>
      )}

    </div>
  );
}
