import { useTranslation } from 'react-i18next';
import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { toast } from 'react-toastify';
import useApi from '../../../utils/api';
import { useAuthentication } from '../../../utils/store';
import { columnsConfig } from '../config';
import { AppContext } from '../../../utils/store/app';

const useNotificationsPageHook = () => {
  const { authGet, authPost } = useApi();
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const { fetchGlobalNotificationsCount } = useContext(AppContext);

  const [notificationsData, setNotificationsData] = useState([]);
  const [notificationsDataLoaded, setNotificationsDataLoaded] = useState(false);
  const [readNotification, setReadNotification] = useState(null);

  const fetchNotifications = useCallback(
    async () => {
      try {
        const response = await authGet('/global-notify/me');
        setNotificationsData(response.data);

        setNotificationsDataLoaded(true);
      } catch (error) {
        console.error(error);
        toast.error(t('Error finding notifications'));
      }
    },
    [authGet],
  );

  const handleOpenReadNotificationModal = useCallback(
    async (notify) => {
      setReadNotification(notify);

      if (notify.unread) {
        await authPost('global-notify/complete', { data: [notify.id] });
        fetchNotifications();
        fetchGlobalNotificationsCount();
      }
    },
    [setReadNotification, authPost],
  );

  const handleCloseReadNotificationModal = useCallback(
    async () => setReadNotification(null),
    [setReadNotification],
  );

  // Columns
  const columnsConfiguration = columnsConfig(t, handleOpenReadNotificationModal);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return {
    t,
    user,
    
    columnsConfiguration,

    notificationsData,
    notificationsDataLoaded,

    readNotification,
    handleCloseReadNotificationModal,
  };
};

export default useNotificationsPageHook;
