/* eslint-disable react/no-danger */
import { useContext } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import Modal from '../../../../components/Modal';
import { GlobalBannerContext } from '../context';
import Banner from '../../../../components/Banner';
import Button from '../../../../components/Button';

const DeleteBannerModal = () => {
  const {
    t,

    bannerToDelete,
    formSubmitting,
    handleDeleteSubmit,
    handleCloseDeleteModal,
  } = useContext(GlobalBannerContext);

  return (
    bannerToDelete && (
      <Modal
        title={t('Delete Banner')}
        width="100%"
        open={!!bannerToDelete}
        handleClose={handleCloseDeleteModal}
        modalStyle={{ maxWidth: '850px', margin: '0 auto' }}
      >
        <Typography>{bannerToDelete.title}</Typography>
        <Typography>
          <b>Start Date:</b>
          {' '}
          {dayjs(bannerToDelete.startDate).tz('UTC').format('MMM DD, YYYY')}
        </Typography>
        <Typography>
          <b>Start Date:</b>
          {' '}
          {dayjs(bannerToDelete.endDate).tz('UTC').format('MMM DD, YYYY')}
        </Typography>
        <Banner
          data={{
            message: (
              <span
                dangerouslySetInnerHTML={{ __html: bannerToDelete.message }}
              />
            ),
          }}
        />
        <Button
          type="button"
          className="w-full mb-5"
          onClick={() => {
            if (!formSubmitting) {
              handleDeleteSubmit({ bannerId: bannerToDelete.id });
            }
          }}
          disabled={formSubmitting}
        >
          <span>Delete</span>
        </Button>
      </Modal>
    )
  );
};

export default DeleteBannerModal;
