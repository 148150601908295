import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Layout from '../../../components/CardLayout';
import { GlobalNotifyContext, GlobalNotifyProvider } from './context';
import NotifyListRow from './components/NotifyListRow';
import AddEditGlobalNotifyModal from './components/AddEditGlobalNotifyModal';
import DeleteGlobalNotifyModal from './components/DeleteGlobalNotifyModal';
import ComponentLoader from '../../../components/ComponentLoader';

const AdminGlobalNotifyPage = () => {
  const { navigate } = useNavigate();
  const {
    t, user, allGlobalNotifyData, allGlobalNotifyDataLoaded, fetchGlobalNotifyData, 
  } = useContext(GlobalNotifyContext);

  if (!user) {
    navigate('/landing');
  }

  useEffect(() => {
    fetchGlobalNotifyData();
  }, []);

  return (
    <Container className="admin-notify-page">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
            marginBottom: '16px',
          }}
        >
          <span className="primary-text-gradient">
            {t('Global Notify Administration')}
          </span>
        </Typography>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', paddingTop: '14px' }}
        >
          <AddEditGlobalNotifyModal />
          <DeleteGlobalNotifyModal />
        </Grid>
        
        {allGlobalNotifyDataLoaded ? (
          <>
            <Grid container marginTop={6}>
              <Grid item xs={8} md={10}>
                <Typography fontSize="16px" fontWeight="600">
                  Messages
                </Typography>
              </Grid>
              <Grid item xs={4} md={2} display="flex" justifyContent="center">
                <Typography fontSize="16px" fontWeight="600">
                  Actions
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div className="divider" />
            </Grid>
            <Grid
              container
              sx={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column',
              }}
            >
              {allGlobalNotifyData?.map((notify, idx) => <NotifyListRow key={idx} notify={notify} />)}
            </Grid>
          </>
        ) : (
          <ComponentLoader />
        )}
      </Layout>
    </Container>
  );
};

export default () => (
  <GlobalNotifyProvider>
    <AdminGlobalNotifyPage />
  </GlobalNotifyProvider>
);
