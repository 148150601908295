import LoopIcon from '@mui/icons-material/Loop';
import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import dayjs from 'dayjs';
import PlayVideo from '../../../../components/PlayVideo';
import { renderHourFromSeconds } from '../../../../utils/string';
import { VideoContext } from '../context';

const VideoRow = ({ video }) => {
  const { t } = useContext(VideoContext);
  const [videoMetaData, setVideoMetadata] = useState(null);

  return (
    <Grid
      backgroundColor="rgba(0, 0, 0, 0.12)"
      className="video-page-container"
      style={{ position: 'relative', padding: '20px' }}
      item
      size={4}
      xs={12}
      md={6}
      lg={4}
    >
      <Grid
        item
        xs={12}
        lg={12}
        sx={{
          display: 'flex',
          // justifyContent: { xs: 'center', md: 'flex-start', lg: 'flex-end' },
          alignItems: 'center',
          paddingTop: { xs: 2, lg: 0 },
        }}
      >
        <PlayVideo
          title="Watch the video!"
          setVideoMetadata={setVideoMetadata}
          url={video?.url}
        />
      </Grid>
      <Grid item>
        <Grid item xs={12} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
          <Typography
            fontSize="8px"
            fontWeight="600"
            color="rgba(255, 255, 255, 0.7)"
          >
            {t('Title')}
          </Typography>
          <Typography fontSize="14px">{video?.title}</Typography>
        </Grid>
        <Grid container width="100%">
          
          <Grid item xs={6} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
            <Typography
              fontSize="8px"
              fontWeight="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              {t('Release Date')}
            </Typography>
            <Typography fontSize="14px">
              {dayjs(video?.releaseDate).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
            <Typography
              fontSize="8px"
              fontWeight="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              {t('Duration')}
            </Typography>
            <Typography fontSize="14px">
              {videoMetaData ? (
                <>
                  {videoMetaData.elapsed > 0 && (
                    <span>
                      {renderHourFromSeconds(videoMetaData.elapsed)}
                      {' '}
                      /
                      {' '}
                    </span>
                  )}
                  <span>{renderHourFromSeconds(videoMetaData.duration || 0)}</span>
                </>
              ) : (
                <LoopIcon color="primary" className="loading-icon" />
              )}
            </Typography>
          </Grid>
          <Grid item sx={{ paddingTop: { xs: 2 } }}>
            <Typography
              fontSize="8px"
              fontWeight="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              {t('Description')}
            </Typography>
            <Typography fontSize="14px">{video?.description}</Typography>
          </Grid>
        </Grid>
      </Grid>
      
    </Grid>
  );
};

export default VideoRow;
