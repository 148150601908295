import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { renderHourFromSeconds } from '../../utils/string';

const ClockTimer = ({
  prefix = '', suffix = '', countdown = false, styles = {}, 
}) => {
  const [now, setNow] = useState(dayjs().tz('GMT'));

  const getCurrentTime = now.format('HH:mm:ss');

  const getCountdownToMidnight = () => {
    const midnight = now.endOf('day').add(1, 'second');

    const diffMilliseconds = midnight.diff(now);

    return renderHourFromSeconds(diffMilliseconds / 1000);
  };

  useEffect(() => {
    const intervalId = setInterval(() => setNow(dayjs().tz('GMT')), 1200);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Grid display="flex" style={styles}>
      <Typography fontSize="16px" className="primary-text-gradient">
        {countdown
          ? `${prefix}${getCountdownToMidnight()}${suffix}`
          : `${prefix}${getCurrentTime}${suffix}`}
      </Typography>
    </Grid>
  );
};

export default ClockTimer;
