/* eslint-disable react/jsx-props-no-spreading */
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';

const CustomPopper = ({
  open,
  anchorEl,
  children,
  name = 'popper',
  placement = 'bottom-start',
  width = '130px',
}) => {
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? name : undefined;
  return (
    <Popper
      placement={placement}
      sx={{
        zIndex: 99999,
        width,
      }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={250}>
          <Box
            sx={{ border: 'solid 1px rgb(255, 255, 255, 0.05)', p: 1, bgcolor: 'background.paper' }}
          >
            {children}
          </Box>
        </Fade>
      )}
    </Popper>
  );
};
export default CustomPopper;
