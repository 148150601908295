import { useContext, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import Typography from '@mui/material/Typography';
import { Badge } from '@mui/material';
import CustomPopper from '../../../Popper';
import Icon from '../../../Icon';
import { NotifyPopperContext, NotifyPopperProvider } from './context';
import { AppContext } from '../../../../utils/store/app';

const NotifyPopper = () => {
  const {
    open,
    anchorEl,
    handleIconClick,
    handleNavigateNotifications,
  } = useContext(NotifyPopperContext);

  const {
    globalNotificationsCount,
    fetchGlobalNotificationsCount,
  } = useContext(AppContext);

  useEffect(() => {
    fetchGlobalNotificationsCount();
  }, []);
  
  return (
    <>
      <IconButton
        aria-describedby="id"
        color="inherit"
        onClick={handleIconClick}
        style={{ paddingBottom: '3px' }}
      >
        <Badge color="secondary" badgeContent={globalNotificationsCount}>
          <Icon
            name="Bell"
            width={26}
            height={26}
          />
        </Badge>
      </IconButton>

      <CustomPopper
        anchorEl={anchorEl}
        handleClick={handleIconClick}
        open={open}
        name="notify-popper"
      >
        <Typography
          onClick={() => handleNavigateNotifications()}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            ':hover': { opacity: 0.8 },
          }}
          color="primary"
        >
          <span className="notify-title">Notifications</span>
          {' '}
          <EmailIcon
            sx={{ fontSize: '13px' }}
            className="my-auto py-auto"
            color="primary"
          />
        </Typography>
      </CustomPopper>
    </>
  );
};

export default () => (
  <NotifyPopperProvider>
    <NotifyPopper />
  </NotifyPopperProvider>
);
