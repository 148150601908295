/* eslint-disable react/no-danger */
import { useContext } from 'react';
import { Typography } from '@mui/material';
import { GlobalNotifyContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const DeleteGlobalNotifyModal = () => {
  const {
    t,
    deleteNotifyData,
    formSubmitting,
    handleFormDeleteSubmitModal,
    handleCloseDeleteModal,
  } = useContext(GlobalNotifyContext);

  return (
    deleteNotifyData && (
      <Modal
        title={t('Delete Banner')}
        width="100%"
        open={!!deleteNotifyData}
        handleClose={handleCloseDeleteModal}
        modalStyle={{ maxWidth: '850px', margin: '0 auto' }}
      >
        <Typography fontSize="16px" fontWeight={600}>
          {deleteNotifyData.title}
        </Typography>
        <span dangerouslySetInnerHTML={{ __html: deleteNotifyData.message }} />
        <Button
          type="button"
          className="w-full mb-5 mt-5"
          onClick={() => {
            if (!formSubmitting) {
              handleFormDeleteSubmitModal(deleteNotifyData.id);
            }
          }}
          disabled={formSubmitting}
        >
          <span>Delete</span>
        </Button>
      </Modal>
    )
  );
};

export default DeleteGlobalNotifyModal;
