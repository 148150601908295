import {
  useContext, useState, useMemo,
  useEffect,
  useLayoutEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../../../utils/store/authentication';
import { leaderboardColumnsConfig, leaderboardFullColumnsConfig, leaderboardYesterdayColumnsConfig } from '../config';
import useApi from '../../../utils/api';

const PAGE_SIZE = 10;

const useLeaderboardApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const [loadedData, setLoadedData] = useState(false);
  const [topTotal, setTopTotal] = useState(5);
  const [loadingTopRankYesterday, setLoadingTopRankYesterday] = useState(true);
  const [loadingTopRank, setLoadingTopRank] = useState(true);
  const [loadingAllRank, setLoadingAllRank] = useState(true);
  const [allRankData, setAllRankData] = useState([]);
  const [topRankYesterdayData, setTopRankYesterdayData] = useState([]);
  const [topRankData, setTopRankData] = useState([]);
  const [paginationData, setPaginationData] = useState();

  const { authGet } = useApi();

  const getPaginatedRankData = useCallback(
    async (page = 1, published = undefined, search = undefined, orderBy = undefined) => {
      try {
        const response = await authGet('/gaming-points', {
          params: {
            page, perPage: PAGE_SIZE, published, search, orderBy,
          },
        });
        setAllRankData(response.data || []);
        setPaginationData({
          totalUsers: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });

        setLoadedData(true);
      } catch (err) {
        console.log(err);
      }
    },
    [authGet, paginationData],
  );

  const getTopYesterdayData = useCallback(
    async (top) => {
      setLoadingTopRankYesterday(true);
      const response = await authGet('/gaming-points/yesterday', {
        params: { top },
      });

      const { data } = response;

      setTopRankYesterdayData(data);
      setLoadingTopRankYesterday(false);
    },
    [topTotal, authGet],
  );

  // Ranking ===================================

  const getTopRank = (top) => {
    setLoadingTopRank(true);
    setTopRankData(allRankData.slice(0, top));
    setLoadingTopRank(false);
  };

  useLayoutEffect(() => {
    if (!loadedData) {
      getPaginatedRankData();
      getTopYesterdayData(10);
    }
  }, []);

  useEffect(() => {
    if (allRankData?.length > 0) {
      // Set the top 5 all time data
      getTopRank(topTotal);

      setLoadingAllRank(false);
    }
  }, [loadedData]);
  // Ranking ===================================

  // Columns Configuration ===================================
  const leaderboardTopYesterdayColumns = useMemo(() => leaderboardYesterdayColumnsConfig(t), [t]);
  const leaderboardTopColumns = useMemo(() => leaderboardColumnsConfig(t), [t]);
  const leaderboardColumns = useMemo(() => leaderboardFullColumnsConfig(t), [t]);

  // Columns Configuration ===================================

  return {
    t,
    user,

    // Ranking
    topTotal,
    setTopTotal,
    topRankYesterdayData,
    loadingTopRankYesterday,
    setLoadingTopRankYesterday,
    topRankData,
    loadingTopRank,
    setLoadingTopRank,
    allRankData,
    loadingAllRank,
    setLoadingAllRank,
    getPaginatedRankData,

    paginationData,

    // Columns configuration
    leaderboardTopColumns,
    leaderboardColumns,
    leaderboardTopYesterdayColumns,
  };
};

export default useLeaderboardApi;
