/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Box } from '@mui/material';
import React from 'react';
import ProgressBar from '../ProgressBar';
import { useAuthentication } from '../../utils/store';
import { nextTeam, teamVolumes, teams } from './config';
import InfoBubbleModal from '../InfoBubbleModal';

export default function TeamsCards({
  volume, children, noContainer, cardClassName,
  personalVolume, gamersVolume, qualificationPercent, challengeVolume,
  loadingVolumes, totalChallengePacks,
}) {
  const { t } = useTranslation();
  const { user } = useAuthentication();

  const getPercentage = (val, i = 1) => {
    const vol = teams[`team${i + 1}`];
    const yearlyEarning = Number(vol || 0);
    const percentage = (val / yearlyEarning) * 100;
    return percentage > 100 ? 100 : percentage;
  };

  const renderCard = (i, config) => {
    let percentage = 0;
    if (i === 2 || i === 3) {
      percentage = getPercentage((Number(config?.totalVolume || 0)), i);
    } else {
      percentage = qualificationPercent?.progress || 0;
    }

    // percentage = getPercentage((Number(config?.totalVolume || 0)));
    const tv = !Number.isNaN(config.teamVolume) ? Number(config.teamVolume) : 0;
    const totv = !Number.isNaN(config.totalVolume) ? Number(config.totalVolume) : 0;
    const percToReachNext = Number(teams[`team${i + 1}`] || 0)
      - (Number(config?.teamVolume || 0));

    return (
      <div className={clsx('card', cardClassName)}>
        {user.qualified ? (
          <Typography sx={{ textWrap: 'nowrap' }} className="bold" key={i}>
            {`Team ${i}`}
          </Typography>
        ) : (
          ''
        )}
        <div className="flex">
          <Typography sx={{ textWrap: 'nowrap' }}>
            {`${user?.qualified ? `Team Volume: $${tv ? tv.toLocaleString('en-us') : 0}` : 'Qualification Progress'}`}
          </Typography>
          {!user.qualified ? (
            <div className="icon-cont">
              <InfoBubbleModal iconColor="var(--primary-color)" title="Qualification Progress">
                <Typography>
                  {t('You are unqualified. Get active and qualified to start receiving commissions!.')}
                </Typography>
                <Typography sx={{ marginTop: '10px' }}>
                  <span style={{ color: 'var(--primary-color)' }}>{t('To be active:')}</span>
                  {' '}
                  {t('Have at least $100 in PV by either signing up a gamer or becoming a gamer yourself with the starter pack.')}
                </Typography>
                <Typography sx={{ marginTop: '10px' }}>
                  <span style={{ color: 'var(--primary-color)' }}>{t('To be qualified:')}</span>
                  {' '}
                  {t('Maintain 3 active, personally sponsored ambassadors in your team.')}
                </Typography>
                <Typography sx={{ marginTop: '10px' }}>
                  {t('For more information please visit our ')}
                  <a href="https://memegames.ai/comp-plan" target="_self" rel="noreferrer">
                    {t('Compensation Plan')}
                  </a>
                </Typography>
              </InfoBubbleModal>
            </div>
          ) : ''}
        </div>

        {/* <div className="border-bottom" /> */}

        {/* <div className="flex">
          <Typography sx={{ textWrap: 'nowrap' }}>
            Progress:
          </Typography>
          <div className="flex flex-column justify-center flex-1">
            <div className="flex">
              <div className="flex flex-column justify-center flex-1 mr-2">
                <ProgressBar variant="determinate" value={percentage || 0} />
              </div>
              <Typography sx={{ textWrap: 'nowrap' }}>
                {percToReachNext > 0 && user.qualified ? `$${percToReachNext.toLocaleString('us-en')} ` : `${percentage || 0}%`}
              </Typography>
            </div>
          </div>
        </div> */}
      </div>
    );
  };

  const renderPVCard = (i) => {
    return (
      <div className={clsx('card', cardClassName)}>
        <Typography sx={{ textWrap: 'nowrap' }} className="bold" key={i}>
          Personal Volume
          <span className="green" style={{ float: 'right' }}>{`$${Number(personalVolume + gamersVolume).toLocaleString('en-us')}`}</span>
        </Typography>
        <div className="border-bottom" />
        <Typography sx={{ textWrap: 'nowrap' }} className={clsx('peronalAmount')}>
          {'Activated Packs: '}
          <span className="green">{`$${Number(personalVolume).toLocaleString('en-us')}`}</span>
        </Typography>
        <Typography sx={{ textWrap: 'nowrap' }} className={clsx('peronalAmount')}>
          {'Your Gamers Vol.: '}
          <span className="green">{`$${Number(gamersVolume || 0).toLocaleString('en-us')}`}</span>
        </Typography>
        <Typography sx={{ textWrap: 'nowrap' }} className={clsx('peronalAmount')}>
          {'Challenge Vol.: '}
          <span className="green">{`$${Number(challengeVolume || 0).toLocaleString('en-us')}`}</span>
        </Typography>
        <Typography sx={{ textWrap: 'nowrap' }} className={clsx('peronalAmount')}>
          {'Total Challenge Packs: '}
          <span className="green">{`${Number(totalChallengePacks || 0).toLocaleString('en-us')}`}</span>
        </Typography>
      </div>
    );
  };

  const team1Config = {
    teamVolume: volume?.team1Volume, totalVolume: volume?.team1Volume,
  };

  const team2Config = {
    teamVolume: volume?.team2Volume, totalVolume: volume?.team2Volume,
  };

  const team3Config = {
    teamVolume: volume?.team3Volume, totalVolume: volume?.team3Volume,
  };

  if (noContainer) {
    return (
      <div className="genealogy-teams flex">
        <div className="row-items flex">
          {renderPVCard(4)}
          {renderCard(1, team1Config)}
          {user?.team2Open ? renderCard(2, team2Config) : ''}
          {user?.team3Open ? renderCard(3, team3Config) : ''}
        </div>
      </div>
    );
  }

  return (
    <Container
      className="genealogy-teams"
      sx={{
        display: 'flex', justifyContent: 'start', gap: 3, mt: 3, mb: 3,
      }}
    >
      <div className="flex row-items">
        <div>{children}</div>
        {renderPVCard(4)}
        {renderCard(1, team1Config)}
        {user?.team2Open ? renderCard(2, team2Config) : ''}
        {user?.team3Open ? renderCard(3, team3Config) : ''}
      </div>
    </Container>
  );
}
