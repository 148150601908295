import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';

const useNotifyPopperHook = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleNavigateNotifications = useCallback(
    () => {
      navigate('/notifications');
      setOpen(false);
    },
    [navigate, setOpen],
  );

  return {
    open,
    anchorEl,
    handleIconClick,
    handleNavigateNotifications,
  };
};

export default useNotifyPopperHook;
