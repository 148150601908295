import { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AdminBlogContext } from '../../context';
import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/Inputs/TextInput';
import Modal from '../../../../../components/Modal';
import ComponentLoader from '../../../../../components/ComponentLoader';

const PostCategoryModal = () => {
  const {
    t,
    fetchPostCategoriesData,
    postCategories,
    postCategoriesLoaded,
    openPostCategoriesModal,
    setOpenPostCategoriesModal,
    postCategory,
    setPostCategory,
    handleAddPostCategorySubmit,
    handleOpenPostCategoryDeleteModal,
  } = useContext(AdminBlogContext);

  useEffect(() => {
    fetchPostCategoriesData();
  }, []);

  return (
    <Modal
      title={t('Post Categories')}
      open={openPostCategoriesModal}
      handleClose={() => setOpenPostCategoriesModal(false)}
      width="600px"
    >
      <TextInput
        className="mb-4"
        value={postCategory.title}
        onChange={(e) => {
          setPostCategory({ id: postCategory.id, title: e });
          if (!(e.length > 0)) {
            setPostCategory({ id: '', title: '' });
          }
        }}
        endAdornment={(
          <div className="flex items-center">
            <Button
              onClick={() => {
                if (postCategory?.title.length > 0 && postCategoriesLoaded) {
                  handleAddPostCategorySubmit(postCategory);
                }
              }}
              className="py-auto my-auto mr-3"
              bordered={!(postCategory?.title.length > 0) || !postCategoriesLoaded}
              disabled={!(postCategory?.title.length > 0) || !postCategoriesLoaded}
              size="sm"
            >
              <span className="no-wrap pl-2 pr-2">
                {postCategory.id.length > 0 ? t('Edit') : t('Add')}
              </span>
            </Button>
          </div>
        )}
      />

      {postCategoriesLoaded ? (
        <Grid container className="post-categories" paddingTop={4}>
          <Grid item xs={8} md={10}>
            <Typography fontSize="16px" fontWeight="600">
              Category
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography fontSize="16px" fontWeight="600">
              Actions
            </Typography>
          </Grid>
          {postCategories.length > 0 && postCategories.map((category, idx) => (
            <Grid container key={idx}>
              <Grid item xs={12}>
                <div className="divider" />
              </Grid>
              <Grid item xs={8} md={10}>
                <Typography fontSize="14px" paddingLeft={1}>
                  {category.title}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2} display="flex" gap={2}>
                <EditIcon
                  sx={{ cursor: 'pointer', display: 'block' }}
                  color="primary"
                  onClick={() => setPostCategory({ id: category.id, title: category.title })}
                />
                <DeleteIcon
                  sx={{ cursor: 'pointer', display: 'block' }}
                  color="primary"
                  onClick={() => handleOpenPostCategoryDeleteModal({ id: category.id, title: category.title })}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <ComponentLoader />
      )}
    </Modal>
  );
};

export default PostCategoryModal;
