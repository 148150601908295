/* eslint-disable react/no-danger */
import { useContext } from 'react';
import { Typography } from '@mui/material';
import { NotificationsPageContext } from '../context';
import Modal from '../../../components/Modal';
import Button from '../../../components/Button';

const ReadNotificationModal = () => {
  const {
    t,
    readNotification,
    handleCloseReadNotificationModal,
  } = useContext(NotificationsPageContext);

  return (
    !!readNotification && (
      <Modal
        width="550px"
        open={!!readNotification}
        handleClose={handleCloseReadNotificationModal}
        title={t('Notification')}
      >
        <div style={{ textAlign: 'center' }}>
          <Typography
            position="relative"
            top="-20px"
            fontSize="18px"
            fontWeight={600}
          >
            <span className="primary-text-gradient">
              {readNotification?.title}
            </span>
          </Typography>
          
          <span dangerouslySetInnerHTML={{ __html: readNotification?.message }} />

          <Button
            onClick={handleCloseReadNotificationModal}
            bordered
            style={{ marginTop: '40px' }}
          >
            {t('Ready')}
          </Button>
        </div>
      </Modal>
    )
  );
};

export default () => (
  <ReadNotificationModal />
);
