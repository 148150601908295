import { Container, Grid, Typography } from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import CategoryIcon from '@mui/icons-material/Category';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import { AdminBlogContext, AdminBlogProvider } from './context';
import PostCategoryModal from './components/PostCategory/PostCategoryModal';
import DeleteCategoryModal from './components/PostCategory/DeleteCategoryModal';
import PostTagModal from './components/PostTag/PostTagModal';
import DeleteTagModal from './components/PostTag/DeleteTagModal';
import Table from '../../../components/Table';
import ComponentLoader from '../../../components/ComponentLoader';
import DeletePostModal from './components/DeletePostModal';

const AdminBlogPage = () => {
  const navigate = useNavigate();
  
  const {
    t,
    fetchPostsData,
    postsData,
    postsDataLoaded,
    blogPostColumnsConfiguration,
    setOpenPostCategoriesModal,
    fetchPostCategoriesData,
    setOpenPostTagsModal,
    fetchPostTagsData,
  } = useContext(AdminBlogContext);

  const handleNewPostButtonClick = () => {
    navigate('/admin/b/post');
  };

  const handleCategoriesButtonClick = () => { 
    setOpenPostCategoriesModal(true);
    fetchPostCategoriesData();
  };

  const handleTagsButtonClick = () => { 
    setOpenPostTagsModal(true);
    fetchPostTagsData();
  };

  useEffect(() => {
    fetchPostsData();
  }, []);

  return (
    <Container className="admin-blog-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 2,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('Blog Administration')}
          </span>
        </Typography>

        <DeletePostModal />

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex', justifyContent: 'center', paddingTop: '14px', gap: '16px', 
          }}
        >
          <Button size="sm" onClick={handleNewPostButtonClick} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span style={{ fontSize: '14px' }}>{t('New Post')}</span>
            <AddCircleIcon />
          </Button>
          <Button size="sm" onClick={handleCategoriesButtonClick} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span style={{ fontSize: '14px' }}>{t('Categories')}</span>
            <CategoryIcon />
          </Button>
          <Button size="sm" onClick={handleTagsButtonClick} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span style={{ fontSize: '14px' }}>{t('Tags')}</span>
            <SellIcon />
          </Button>
        </Grid>

        <PostCategoryModal />
        <DeleteCategoryModal />
        <PostTagModal />
        <DeleteTagModal />

        <Grid item xs={12} mt={6}>
          <div className="divider" />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          {postsDataLoaded ? (
            <Table
              loading={!postsDataLoaded}
              columns={blogPostColumnsConfiguration}
              rows={postsData}
              className="table mb-4"
            />
          ) : (
            <div className="flex justify-center">
              <ComponentLoader />
            </div>
          )}
        </Grid>
      </Layout>
    </Container>
  );
};

export default () => (
  <AdminBlogProvider>
    <AdminBlogPage />
  </AdminBlogProvider>
);
