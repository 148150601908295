import ListView from './components/ListView';
import GridView from './components/GridView';
import { useBreakpoint } from '../../utils/store';

export default function MyCoinPicks() {
  const { isMobile, isTablet } = useBreakpoint();
  return (
    (isMobile && isTablet) ? <ListView /> : <GridView />
  );
}
