import { createContext } from 'react';
import useAdminBlogApi from './hook';

export const AdminBlogContext = createContext({
  t: () => {},
  user: {},

  postsData: [],
  postsDataLoaded: false,
  postDelete: {},
  postFormSubmitting: false,
  postFormErrors: {},
  setPostFormErrors: () => {},

  fetchPostsData: () => {},
  handleAddEditPostFormSubmit: () => {},
  handleDeletePostModalSubmit: () => {},
  handleOpenDeletePostModal: () => {},
  handleCloseDeletePostModal: () => {},
  blogPostColumnsConfiguration: {},
  fetchPostSingleData: () => {},
  postSingle: {},
  postSingleLoaded: false,
  handleCancelEditPostButtonClick: () => {},

  // Categories
  fetchPostCategoriesData: () => {},
  postCategories: [],
  postCategoriesLoaded: false,
  openPostCategoriesModal: {},
  setOpenPostCategoriesModal: () => {},

  postCategory: {},
  setPostCategory: () => {},
  handleAddPostCategorySubmit: () => {},

  postCategoryDelete: {},
  handleOpenPostCategoryDeleteModal: () => {},
  handleClosePostCategoryDeleteModal: () => {},
  handleSubmitPostCategoryDeleteModal: () => {},

  // Tags
  fetchPostTagsData: () => {},
  postTags: [],
  postTagsLoaded: false,
  openPostTagsModal: {},
  setOpenPostTagsModal: () => {},

  // Tags Checkboxes
  postTagsChecks: {},
  postTagsChecksLoaded: false,
  setPostTagsChecks: () => {},
  createPostTagsChecks: () => {},

  postTag: {},
  setPostTag: () => {},
  handleAddPostTagSubmit: () => {},

  postTagDelete: {},
  handleOpenPostTagDeleteModal: () => {},
  handleClosePostTagDeleteModal: () => {},
  handleSubmitPostTagDeleteModal: () => {},
});

export const AdminBlogProvider = ({ children }) => {
  const value = useAdminBlogApi();

  return (
    <AdminBlogContext.Provider value={value}>
      {children}
    </AdminBlogContext.Provider>
  );
};
