import { useContext } from 'react';
import { Context } from '../context';
import Table from '../../../components/Table';
import ResultsBox from '../../AdminReports/components/ResultsBox';

const EarningsHistory = () => {
  const {
    walletEarningsData,
    walletEarningsLoaded,
    walletEarningsColumnsConfiguration,
    walletYesterdayEarningsData,
  } = useContext(Context);

  return (
    <div className="flex" style={{ flexDirection: 'column' }}>
      <div className="wallet-earnings-results">
        <ResultsBox
          title="Yesterday"
          result={walletYesterdayEarningsData}
          money
        />
        <ResultsBox
          title="Total Earnings"
          result={walletEarningsData.reduce((acc, curr) => acc + curr.amount, 0)}
          money
        />
        <ResultsBox
          title="Total Transactions"
          result={walletEarningsData.reduce((acc, curr) => acc + curr.transactions, 0)}
        />
      </div>
      <Table
        loading={!walletEarningsLoaded}
        columns={walletEarningsColumnsConfiguration}
        rows={walletEarningsData}
        getRowId={(row) => `${row.year}-${row.month}`}
        className="table mb-4"
      />
    </div>
  );
};

export default EarningsHistory;
