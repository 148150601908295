import { useContext, useEffect, useState } from 'react';
import {
  Checkbox, Container, FormControlLabel, Grid, Pagination, Typography, 
} from '@mui/material';

import { PublicBlogContext, PublicBlogProvider } from './context';
import Layout from '../../../components/CardLayout';
import PostSingleCard from './components/PostSingleCard';
import ComponentLoader from '../../../components/ComponentLoader';
import Select from '../../../components/Inputs/Select';

const PublicBlogPage = () => {
  const {
    t,
    fetchPublicBlogData,
    blogPostsData,
    blogPostsDataLoaded,
    blogPaginationData,
    getAllTags,
    tags,
    tagsLoaded,
    getAllCategories,
    categories,
    categoriesLoaded,
  } = useContext(PublicBlogContext);
  
  const [currentCategoory, setCurrentCategory] = useState(undefined);
  const [currentTag, setCurrentTag] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentNewest, setCurrentNewest] = useState(true);
  
  useEffect(() => {
    getAllCategories();
    getAllTags();
  }, []);

  useEffect(() => {
    fetchPublicBlogData({
      page: currentPage, category: currentCategoory, tag: currentTag, newest: currentNewest, 
    });
  }, [currentCategoory, currentTag, currentPage, currentNewest]);

  return (
    <Container className="blog-page" maxWidth="xl">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingLeft: 0,
          paddingRight: 0,
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mt: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('MemeGames Blog')}
          </span>
        </Typography>

        <Grid style={{ padding: '10px 0' }}>
          <div className="divider" />
        </Grid>

        <Grid
          container
          sx={{ marginBottom: '24px', alignItems: 'center', display: { xs: 'block', sm: 'flex' } }}
          gap={4}
          
        >
          <Grid sx={{ display: { xs: 'block', sm: 'flex' }, gap: '12px', justifyContent: 'space-between' }}>
           
            {/* Filter by Categories */}
            {categoriesLoaded && categories.length > 0 && (
            <Grid size={{ xs: 12, sm: 8 }} sx={{ justifyContent: 'center', marginBottom: { xs: '20px', sm: 0 }, display: { sm: 'flex' } }}>
              
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '12px',
                }}
                sx={{ marginBottom: { xs: '10px', sm: 0 } }}
              >
                By Category:
              </Typography>
              <Select
                name="categories-filter-select"
                className="categories-filter-select "
                placeholder="All Categories"
                size="sm"
                sx={{ minWidth: '200px' }}
                value={currentCategoory}
                onChange={(v) => {
                  if (v === 'default') {
                    setCurrentCategory(undefined);
                    return;
                  }
                  setCurrentCategory(v);
                }}
                options={categories.map((c) => c)}
              />
            </Grid>
            )}
            {/* Filter by Tags */}
            {tagsLoaded && tags.length > 0 && (
            <Grid sx={{ justifyContent: 'center', marginBottom: { xs: '20px', sm: 0 }, display: { sm: 'flex' } }} size={{ xs: 12, sm: 8 }}>
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingRight: '12px',
                }}
                sx={{ marginBottom: { xs: '10px', sm: 0 } }}
              >
                By Tag:
              </Typography>
              <Select
                name="categories-filter-select"
                className="categories-filter-select"
                placeholder="All Categories"
                size="sm"
                sx={{ minWidth: '200px' }}
                value={currentTag}
                onChange={(v) => {
                  if (v === 'default') {
                    setCurrentTag(undefined);
                    return;
                  }
                  setCurrentTag(v);
                }}
                options={tags.map((c) => c)}
              />
            </Grid>
            )}
          </Grid>
          <Grid sx={{ marginLeft: { sm: 'auto' }, justifyContent: 'center' }}>
            <FormControlLabel
              control={(
                <Checkbox
                  name="Sort"
                  value={currentNewest}
                  checked={currentNewest}
                  onChange={() => {
                    setCurrentNewest(!currentNewest);
                  }}
                  sx={{ color: 'white', fontSize: '12px !important' }}
                />
                      )}
              label={(
                <div className="flex">
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      letterSpacing: '1.2px',
                    }}
                  >
                    {t('Newest')}
                  </Typography>
                </div>
                      )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {(blogPostsDataLoaded && blogPostsData?.length < 1) && (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
              fontWeight: 600,
              mt: 1,
              width: '100%',
              paddingTop: '40px',
              paddingBottom: '40px',
            }}
          >
            <span className="primary-text-gradient">
              There are not posts available
            </span>
          </Typography>
          )}
          {/* && postSingle.imageUrl !==  */}
          {blogPostsDataLoaded ? (
            blogPostsData.map((post) => (
              <Grid item xs={12} md={6} xl={4} key={post.id} display="flex">
                <PostSingleCard post={post} />
              </Grid>
            ))
          ) : (
            <ComponentLoader />
          )}
        </Grid>
        {(blogPaginationData && blogPaginationData?.total > 1) && (
          <div className="flex justify-end mt-4">
            <Typography sx={{ mt: 0.5 }}>
              {`Total of: ${blogPaginationData.total} pages`}
            </Typography>
            <Pagination
              size="small"
              siblingCount={0}
              count={blogPaginationData?.total}
              page={blogPaginationData?.page}
              onChange={(val, page) => {
                setCurrentPage(page);
              }}
            />
          </div>
        )}
      </Layout>
    </Container>
  );
};

export default () => (
  <PublicBlogProvider>
    <PublicBlogPage />
  </PublicBlogProvider>
);
