import { useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import {
  Container, Grid, Pagination, Typography, 
} from '@mui/material';
import { AdminVideoContext, AdminVideoProvider } from './context';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import AddEditVideoModal from './components/AddEditVideoModal';
import VideoRow from './components/VideoRow';
import ComponentLoader from '../../../components/ComponentLoader';
import DeleteVideoModal from './components/DeleteVideoModal';

const AdminVideoPage = () => {
  const {
    t,
    user,
    getVideosData,
    handleOpenModalToAdd,
    videosData,
    videoDataLoaded,
    paginationData,
    handleChangeOrder,
  } = useContext(AdminVideoContext);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container maxWidth="xl" className="admin-video-page">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          {t('Videos Administration')}
        </Typography>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className="divider" />
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingTop: '14px' }}>
          <Button size="sm" onClick={handleOpenModalToAdd}>
            <span>Add Video</span>
          </Button>
        </Grid>
        
        <AddEditVideoModal />
        <DeleteVideoModal />
      </Layout>

      {!videoDataLoaded && (
        <Layout
          maxWidth="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <ComponentLoader />
        </Layout>
      )}

      {(videoDataLoaded && videosData.length > 0) && (
        <Layout
          maxWidth="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            mt: 6,
          }}
        >
          <Grid container spacing={4}>
            {videosData.map((video, idx) => (
              <VideoRow video={video} first={idx === 0 ? true : undefined} last={idx === videosData.length - 1 ? true : undefined} reorder={(moveForward) => { handleChangeOrder({ videoId: video.id, moveForward, callBack: () => getVideosData({ page: currentPage }) }); }} key={idx} />
            ))}
          </Grid>
        </Layout>
      )}

      {(paginationData && paginationData.totalVideos > 25) && (
        <div className="flex justify-end mt-4">
          <Typography sx={{ mt: 0.5 }}>
            {`Total of: ${paginationData.totalVideos} episodes`}
          </Typography>
          <Pagination
            count={paginationData?.total}
            page={paginationData?.page}
            onChange={(val, page) => {
              getVideosData({ page });
              setCurrentPage(page);
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default () => (
  <AdminVideoProvider>
    <AdminVideoPage />
  </AdminVideoProvider>
);
