import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import { PendingWithdrawalsContext, PendingWithdrawalsProvider } from './context';
import Table from '../../../components/Table';
import PayWithdrawModal from './components/PayWithdrawModal';
import Button from '../../../components/Button';
import { downloadCSV } from '../../../utils/csv';

const PendingWithdrawals = () => {
  const { navigate } = useNavigate();
  const {
    t,
    user,

    withdrawDataColumnsConfiguration,
    withdrawData,
    loadedWithdrawData,
  } = useContext(PendingWithdrawalsContext);

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container className="admin-pending-withdrawals">
      <Layout
        maxWidth="100%"
        sx={{
          mt: 6,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">{t('Pending Withdrawals')}</span>
        </Typography>
      </Layout>
      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        {loadedWithdrawData ? (
          <>
            <div className="flex justify-end mb-4">
              <Button onClick={() => {
                const currentDate = new Date().toISOString().split('T')[0];
                downloadCSV(withdrawData, `pending_withdrawals_${currentDate}.csv`);
              }}
              > 
                {t('Download CSV')}
              </Button>
            </div>
            <Table
              loading={!loadedWithdrawData}
              columns={withdrawDataColumnsConfiguration}
              rows={withdrawData}
              className="table mb-4"
            />
          </>
          
        ) : (
          <div className="flex justify-center">
            <ComponentLoader />
          </div>
        )}
      </Grid>
      <PayWithdrawModal />
    </Container>
  );
};

export default () => (
  <PendingWithdrawalsProvider>
    <PendingWithdrawals />
  </PendingWithdrawalsProvider>
);
