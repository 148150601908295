import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import {
  Container, Pagination, Typography, Grid, 
} from '@mui/material';
import { VideoContext, VideoProvider } from './context';
import Layout from '../../../components/CardLayout';
import VideoRow from './component/VideoRow';
import ComponentLoader from '../../../components/ComponentLoader';

const VideoPage = () => {
  const {  
    t,
    user,
    getVideosData,
    videosData,
    videosDataLoaded,
    paginationData,
  } = useContext(VideoContext);
  
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container
      maxWidth="xl"
      className="video-page"
      sx={{ paddingX: { xs: 0, lg: '16px' } }}
    >
      <Typography
        sx={{
          marginTop: '55px',
          display: 'flex',
          justifyContent: 'center',
          fontSize: '32px',
          fontWeight: 600,
          mb: 1,
          width: '100%',
        }}
      >
        <span className="primary-text-gradient">{t('Videos')}</span>
      </Typography>
      <Layout
        maxWidth="100%"
        className="video-list"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
          gap: 8,
        }}
      >
        
        {!videosDataLoaded && <ComponentLoader />}
        {videosDataLoaded && videosData.length > 0 ? (
          <Grid container spacing={4}>
            {videosData.map((video, idx) => (
              <VideoRow video={video} key={idx} />
            ))}
          </Grid>
        ) : (
          <Typography fontSize="16px" textAlign="center">
            Stay tuned.
          </Typography>
        )}
      </Layout>
      {paginationData && paginationData.totalVideos > 25 ? (
        <div className="flex justify-end mt-4">
          <Typography sx={{ mt: 0.5 }}>
            {`Total of: ${paginationData.totalVideos} episodes`}
          </Typography>
          <Pagination
            count={paginationData?.total}
            page={paginationData?.page}
            onChange={(val, page) => {
              getVideosData({ page });
            }}
          />
        </div>
      ) : null}
    </Container>
  );
};

export default () => (
  <VideoProvider>
    <VideoPage />
  </VideoProvider>
);
