import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../utils/store/authentication';
import useApi from '../../../utils/api';

const useRedeemTokenApi = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const { authGet, authPost } = useApi();
  const [loadedGamesData, setLoadedGamesData] = useState(false);
  const [gamesData, setGamesData] = useState([]);
  const [loadedWalletData, setLoadedWalletData] = useState(false);
  const [walletData, setWalletData] = useState([]);

  const getGameData = useCallback(
    async () => {
      try {
        // setLoadedGamesData(false);
        const response = await authGet('/schedule-game/victories');
        setGamesData(response);
        setLoadedGamesData(true);
      } catch (err) {
        console.log(err);
      }
    },
    [authGet],
  );

  const getWalletData = useCallback(
    async () => {
      try {
        const result = await authGet('/gamer-wallet');
        setWalletData(result);

        setLoadedWalletData(true);
      } catch (err) {
        console.log(err);
      }
    },
    [authGet],
  );

  const fetchRedeemData = useCallback(
    async () => {
      getWalletData();
      getGameData();
    },
    [getWalletData, getGameData],
  );

  const handleRedeemCoinPackSubmit = useCallback(
    async ({ redeemAmount, passTypeId }) => {
      try {
        await authPost('/redemptions/redeem-packs', { data: { redeemAmount, passTypeId } });

        toast.success('Your redemption has been successfully completed.');
        
        fetchRedeemData();
      } catch (err) {
        console.log(err);
        toast.error(err);
      }
    },
    [authPost],
  );

  useEffect(() => {
    fetchRedeemData();
  }, []);
  
  return {
    t,
    user,
    loadedGamesData,
    gamesData,
    loadedWalletData,
    walletData,
    fetchRedeemData,
    handleRedeemCoinPackSubmit,
  };
};

export default useRedeemTokenApi;
