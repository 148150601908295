/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import CustomPopper from '../../../Popper';
import Icon from '../../../Icon';
import { AuthenticationContext } from '../../../../utils/store/authentication';

export default function UserPopper({ theme }) {
  const { signOut, userImage } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  
  const handleNavigateProfile = () => {
    navigate('profile');
    setOpen((previousOpen) => !previousOpen);
  };

  return (
    <>
      {userImage ? (
        <img
          src={userImage}
          alt="Profile Pic"
          onClick={handleClick}
          style={{
            width: '41px',
            height: '41px',
            borderRadius: '50%',
            cursor: 'pointer',
          }}
        />
      ) : (
        <IconButton aria-describedby="id" color="inherit" onClick={handleClick}>
          <Icon name="User" width={25} height={25} color={theme.PRIMARY_COLOR} />
        </IconButton>
      )}

      <CustomPopper anchorEl={anchorEl} handleClick={handleClick} open={open} name="user-popper">
        <Typography
          onClick={handleNavigateProfile}
          sx={{
            cursor: 'pointer', display: 'flex', justifyContent: 'space-between', mb: 0.5, ':hover': { opacity: 0.8 },
          }}
          color="primary"
        >
          PROFILE
          {' '}
          <ManageAccountsIcon
            sx={{ fontSize: '13px' }}
            className="my-auto py-auto"
            color="primary"
          />
          {' '}
        </Typography>
        <Typography
          onClick={signOut}
          sx={{
            cursor: 'pointer', display: 'flex', justifyContent: 'space-between', ':hover': { opacity: 0.8 }, 
          }}
          color="primary"
        >
          LOG OUT
          {' '}
          <LogoutIcon
            sx={{ fontSize: '13px' }}
            className="my-auto py-auto"
            color="primary"
          />
          {' '}
        </Typography>
      </CustomPopper>
    </>
  );
}
