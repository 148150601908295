// import { useState } from 'react';
import { Grid, Pagination, Typography } from '@mui/material';
import Table from '../../../../components/Table';
import { columns } from './config';
import Layout from '../../../../components/CardLayout';
import AppLoader from '../../../../components/AppLoader';
import useMyCoinPicks from '../../context/hook';

export default function GridView() {
  const {
    loading, rows, paginationData, getMyPicks, setSortModel, sortModel,
  } = useMyCoinPicks();

  return (
    <>
      {loading && <AppLoader overpage />}
      <h1
        style={{
          fontWeight: 600,
          fontSize: '32px',
          textAlign: 'center',
          marginTop: '32px',
        }}
      >
        <span className="primary-text-gradient">My Coin Picks</span>
      </h1>
      <Layout className="my-coin-picks-grid-view" maxWidth="xl" cardMainClassName="pt-10 pl-0 pr-0" size="xsm">
        <Grid container spacing={4} padding={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Table
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                setSortModel(newSortModel);
              }}
              sortingOrder={['desc', 'asc']}
              sortingMode="server"
              columns={columns}
              rows={rows}
              className="table"
              hideFooterPagination
              hideFooter
              getRowId={(row) => row.id}
            />
            {paginationData && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${paginationData.totalItems} votes`}
                </Typography>
                <Pagination
                  count={paginationData?.total}
                  page={paginationData?.page}
                  onChange={(val, page) => {
                    getMyPicks(page);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
}
