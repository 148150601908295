import { createContext, useContext } from 'react';
import use2FA from './hook';

export const OTPContext = createContext({
}); 

export const OTPProvider = ({ children }) => {
  const value = use2FA();
  return <OTPContext.Provider value={value}>{children}</OTPContext.Provider>;
};

export function useOTP() {
  const value = useContext(OTPContext);
  return value;
}
