/* eslint-disable no-unused-vars */
import React, { createContext } from 'react';
import useAppStore from './hook';

export const AppContext = createContext({
  passes: [],
  passTypes: [],
  updatePassTypes: (type = {}) => {},
  createPassTypes: (type = {}) => {},
  packages: [],
  updatePackage: (pack = {}) => {},
  createPackage: (pack = {}) => {},
  ranks: [],
  updateRanks: (rank = {}) => {},
  loadingRanks: false,
  createRanks: (rank = {}) => {},
  getRanks: () => {},
  getPurchasablePassTypes: () => {},
  purchasablePassTypes: () => {},
  loadingPurchasablePassTypes: false,
  fetchGlobalNotificationsCount: () => {},
  setGlobalNotificationsCount: 0,
});

/**
 * GeneralContext Provider
 * @returns {ReactNode}  wrapper for GeneralContext
 */
export const AppProvider = ({ children = null }) => {
  const value = useAppStore();

  return (
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
  );
};
