import { createContext } from 'react';
import useNotificationsPageHook from './hook';

export const NotificationsPageContext = createContext({
  t: () => {},
  user: {},

  columnsConfiguration: [],

  notificationsData: [],
  notificationsDataLoaded: false,

  readNotification: {},
  handleCloseReadNotificationModal: () => {},
});

export const NotificationsPageProvider = ({ children }) => {
  const value = useNotificationsPageHook();

  return (
    <NotificationsPageContext.Provider value={value}>
      {children}
    </NotificationsPageContext.Provider>
  );
};
