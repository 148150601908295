import { PersonRounded } from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { renderNumber } from '../../../../utils/string';

const UserAccordionSummary = ({
  image, name, expiration, personalVolume, isDeleted, mgCoins, team,
}) => {
  const getExpirationClass = () => {
    if (!expiration) {
      return '';
    }

    const today = dayjs();
    const expirationDays = dayjs(expiration).diff(today, 'day');
    let expirationClass = expirationDays <= 7 ? ' expiring' : '';
    expirationClass = expirationDays <= 0 ? ' expired' : expirationClass;

    return expirationClass;
  };

  return (
    <div className="user-accordion-summary w-full">
      {/* {team && (
        <div className="team" style={{ display: 'flex', alignItems: 'center', padding: 1 }}>
          <Avatar sx={{ bgcolor: 'var(--primary-color)' }}>{`T${team}`}</Avatar>
        </div>
      )} */}
      <div className="image">
        {!image ? (
          <PersonRounded fontSize="large" />
        ) : (
          <img
            src={image}
            alt="Profile pic"
            className="profile-pic"
            height="44"
          />
        )}
      </div>
      <div className="info">
        <div className="name">
          <Typography className={`date-expiration${getExpirationClass()}`}>
            {!expiration ? '' : dayjs(expiration).format('MMMM DD, YYYY')}
          </Typography>
          <h3
            style={{
              ...(isDeleted
                ? { textDecoration: 'line-through', color: 'grey' }
                : ''),
            }}
          >
            {name}
          </h3>
        </div>
        <div className="coins">
          <Typography sx={{ mb: 1 }}>MG Coins</Typography>
          <h3>{renderNumber(mgCoins)}</h3>
        </div>
        <div
          className="personal-volume"
          style={{
            visibility: Number(personalVolume) > 0 ? 'visible' : 'hidden',
          }}
        >
          <Typography sx={{ mb: 1 }}>Personal Volume</Typography>
          <h3>{renderNumber(personalVolume)}</h3>
        </div>
      </div>
    </div>
  );
};

export default UserAccordionSummary;
