import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Button from '../Button';
import Modal from '../Modal';
import { renderNumber } from '../../utils/string';
import useApi from '../../utils/api';
import { useAuthentication } from '../../utils/store';
import { dateIsBusinessDay } from '../../utils/date';

const RedeemButton = ({
  totalTokens, gameId, coin, buttonSize = 'sm', buttonText = null,
}) => {
  const { t } = useTranslation();
  const { user } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();
  const { authPost, authGet } = useApi();

  const [processing, setProcessing] = useState(false);
  const [openInstructionsModal, setOpenInstructionsModal] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [openInactiveMessageModal, setOpenInactiveMessageModal] = useState(false);
  const [openInvalidWeekDayModal, setOpenInvalidWeekDayModal] = useState(false);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [openConfirmSubmitModal, setOpenConfirmSubmitModal] = useState(false);
  const [todayTokenRedeems, setTodayTokenRedeems] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [timer, setTimer] = useState(null);
  const [solanaWallet, setSolanaWallet] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const minTokens = Number(process.env.REACT_APP_REDEMPTION_MIN_TOKENS || 2000);

  const getSolanaWallet = useCallback(async () => {
    const _lastTransactions = await authGet('/gamer-wallet/history', {
      params: {
        page: 1, perPage: 5, type: 'Tokens', action: 'redeem',
      },
    });
    const _todayTokenRedeems = _lastTransactions?.data?.filter(
      (trx) => dayjs(trx.createdAt).tz('GMT') > dayjs().tz('GMT').startOf('day').toDate(),
    );
    setTodayTokenRedeems(_todayTokenRedeems || []);

    const _data = await authGet('/wallets');
    const _solana = _data?.find((i) => i.type === 'solana');

    setSolanaWallet(_solana || null);
    setLoadingData(false);
  }, [authGet]);

  const handleOpenRedeemModal = () => {
    setOpenRedeemModal(true);
    getSolanaWallet();
  };

  const handleCloseRedeemModal = () => {
    clearInterval(timer);
    setTimer(null);
    setOpenRedeemModal(false);
    setOpenConfirmSubmitModal(false);
  };

  const handleRedeemModalSubmit = async () => {
    if (!processing) {
      setProcessing(true);

      const redeemData = {
        tokenAmount: minTokens,
        memeCoinId: coin?.id,
        scheduleGameId: gameId,
        solanaWalletKey: solanaWallet?.publicKey,
      };

      try {
        await authPost('/redemptions/redeem-tokens', { data: redeemData });

        navigate('/redeem-token', { state: { reloadWallet: true }, replace: true });

        toast.success(t(`Thank you! You have redeemed ${renderNumber(minTokens)}. Your coins will be delivered to your Solana Wallet the Friday of the week following your redemption.`));
      } catch (err) {
        console.log({ err });
        toast.error(t(err));
      }

      setProcessing(false);

      handleCloseRedeemModal();
    }
  };

  const handleGoToWalletsButton = () => {
    const walletUrl = user.isAffiliate ? '/ambassador-wallets' : '/gamer-wallets';

    navigate(walletUrl);
  };

  const handleOpenPopUpModal = () => {
    setPopupIsOpen(true);
  };

  const handleClosePopUpModal = () => {
    setPopupIsOpen(false);

    if (!dateIsBusinessDay()) {
      setOpenInvalidWeekDayModal(true);
      return;
    }

    if (!user.active) {
      setOpenInactiveMessageModal(true);
      return;
    }

    if (totalTokens >= minTokens) {
      const redeemUrl = '/redeem-token';
      if (location.pathname === redeemUrl) {
        handleOpenRedeemModal();

        setProcessing(true);

        setProcessing(false);
      } else {
        navigate(redeemUrl, { state: { openInstructions: true } });
      }
    } else {
      setOpenMessageModal(true);
    }
  };

  const handleOnClick = async () => {
    handleOpenPopUpModal();
  };

  useEffect(() => {
    if (location.state?.openInstructions) {
      location.state.openInstructions = false;
      setOpenInstructionsModal(true);
    }
  });

  return (
    <>
      <Button
        onClick={handleOnClick}
        size={buttonSize}
        bordered={!(totalTokens >= minTokens)}
      >
        <span className="no-wrap">{buttonText && location.pathname === '/redeem-token' ? t(buttonText) : t('Redeem')}</span>
      </Button>
      {popupIsOpen && (
        <Modal
          title={t('Redeem Tokens')}
          width="550px"
          open={popupIsOpen}
          handleClose={() => { handleClosePopUpModal(); }}
        >
          <p className="mb-8 text-center">
            Redemptions of your MG Tokens can be used to buy new gamer packs and Meme Coins on the
            Solana blockchain! Packs start at 1,350 Tokens and Meme Coins start at 2,000 Tokens.
            Redeem for any Meme Coin you have won with in any of your previous games. We will send
            you $200 worth of that selected Meme Coin the Friday of the week following your redemption,
            the price of the Meme Coin at the time it is processed. Meme Coins are transferred to
            the Solana wallet you have set up in your Gamer Wallet.
          </p>
          <Button onClick={handleClosePopUpModal} bordered>
            <span className="primary-text-gradient">Close to Continue</span>
          </Button>
        </Modal>
      )}
      {openInstructionsModal && (
        <Modal
          title={t('Redeem Tokens')}
          width="550px"
          open={openInstructionsModal}
          handleClose={() => { setOpenInstructionsModal(false); }}
        >
          <p className="mb-4 text-center">Choose a Meme Coin from any of your winning games to receive $200 USD in those tokens at the time of redemption to your attached Solana Wallet!</p>
        </Modal>
      )}
      {openMessageModal && (
        <Modal
          title={t('Not enough Tokens')}
          width="550px"
          open={openMessageModal}
          handleClose={() => { setOpenMessageModal(false); }}
        >
          <p className="mb-4 text-center">{`Minimum redemption is ${renderNumber(minTokens)} Tokens - keep playing!`}</p>
        </Modal>
      )}
      {openInactiveMessageModal && (
        <Modal
          title={t('User Account')}
          width="550px"
          open={openInactiveMessageModal}
          handleClose={() => { setOpenInactiveMessageModal(false); }}
        >
          <p className="mb-10 text-center">
            {t('You must be active to withdraw or redeem tokens. Please purchase a pack to become active!')}
          </p>
          <Button onClick={() => navigate('/packages')} bordered>
            Purchase Coins
          </Button>
        </Modal>
      )}
      {openInvalidWeekDayModal && (
        <Modal
          title={t('Redeem only Business Days')}
          width="550px"
          open={openInvalidWeekDayModal}
          handleClose={() => { setOpenInvalidWeekDayModal(false); }}
        >
          <p className="mb-4 text-center">
            Redemptions are only allowed from Monday to Friday.
          </p>
        </Modal>
      )}

      {openRedeemModal && (
        <Modal
          title={t('Redeem Tokens')}
          width="550px"
          open={openRedeemModal}
          handleClose={() => { handleCloseRedeemModal(); }}
        >
          {processing && (
            <div
              className="flex justify-center"
              style={{
                position: 'absolute', width: '100%', left: 0, top: '50px',
              }}
            >
              <CircularProgress />
            </div>
          )}
          {(loadingData || todayTokenRedeems?.length > 0) ? (
            <Typography fontSize="16px" textAlign="center" mb={2}>
              {loadingData ? <CircularProgress /> : t('Tokens Redemption are allowed only 1/day.')}
            </Typography>
          ) : (
            <>
              <div className="redeem-coin text-center mb-4">
                <div className="redeem-coin-name">
                  <strong>{coin?.name}</strong>
                </div>
                <div className="redeem-coin-image">
                  <img src={coin?.allImages?.large || coin?.image} height={90} width={90} alt={coin?.name} />
                </div>
                <div className="redeem-coin-reward">
                  <span>{`Redeem for ${coin?.name}`}</span>
                </div>
              </div>
              <div className="divider" />
              {(totalTokens > minTokens) ? (
                <p className="mb-4 text-center">{`Redeem ${renderNumber(minTokens)} from your ${renderNumber(totalTokens)} Tokens`}</p>
              ) : (
                <p className="mb-4 text-center">{`Redeem your ${renderNumber(totalTokens)} Tokens`}</p>
              )}
              {solanaWallet ? (
                <Button onClick={() => setOpenConfirmSubmitModal(true)} disabled={processing}>Redeem</Button>
              ) : (
                <>
                  <Button onClick={handleGoToWalletsButton} bordered>Connect Wallet</Button>
                  <Typography textAlign="center" marginTop="8px" fontSize="14px">You need to connect your Solana Wallet to the system</Typography>
                </>
              )}
            </>
          )}
        </Modal>
      )}

      {openConfirmSubmitModal && (
        <Modal
          open={openConfirmSubmitModal}
          title="Are your Sure?"
          handleClose={() => setOpenConfirmSubmitModal()}
          width="550px"
        >
          <Typography
            fontSize="16px"
            fontWeight={500}
            textAlign="center"
            marginBottom="40px"
          >
            {`Do you want to Redeem ${renderNumber(minTokens)} MG Tokens for ${coin?.name}`}
          </Typography>
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              justifyContent: { xs: 'center', md: 'flex-end' },
              flexDirection: { xs: 'column-reverse', md: 'row' },
              alignItems: 'center',
              gap: { xs: '16px', md: '20px' },
            }}
          >
            <Button
              onClick={() => setOpenConfirmSubmitModal(false)}
              size="sm"
              type="button"
              bordered
              style={{ width: '100%', padding: '12px', fontSize: '14px' }}
            >
              {t('No')}
            </Button>
            <Button
              onClick={handleRedeemModalSubmit}
              size="sm"
              type="button"
              style={{ width: '100%', padding: '12px', fontSize: '14px' }}
            >
              {t('Yes')}
            </Button>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default RedeemButton;
