/* eslint-disable no-restricted-syntax */
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';
import { validateAll } from '../../../../components/Form/config';
import { videoFormValidationConfig } from '../config';

const PAGE_SIZE = 25;

const useAdminVideo = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const {
    authGet, authPost, authDelete, authPut,
  } = useApi();
  const [video, setVideo] = useState(null);
  const [videoDelete, setVideoDelete] = useState(null);
  const [videoFormErrors, setVideoFormErrors] = useState(null);
  const [videosData, setvideosData] = useState([]);
  const [videoDataLoaded, setVideosDataLoaded] = useState(false);
  const [videoFormLoading, setVideoFormLoading] = useState(false);
  const [paginationData, setPaginationData] = useState();

  const getVideosData = useCallback(
    async ({ page = 1 }) => {
      try {
        setVideosDataLoaded(false);
        const response = await authGet('/video', {
          params: { page, perPage: PAGE_SIZE },
        });
        setvideosData(response.data || []);

        setPaginationData({
          totalVideos: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
        toast.error('Error fetching videos Data');
      }
      setVideosDataLoaded(true);
    },
    [authGet],
  );

  const handleOpenModalToAdd = useCallback(
    async () => {
      setVideo({
        title: '',
        description: '',
        published: true,
        releaseDate: new Date(),
      });
    },
    [setVideo],
  );

  const handleOpenModalToEdit = useCallback(
    async (videoInfo) => {
      setVideo(videoInfo);
    },
    [setVideo],
  );

  const handleCloseModal = useCallback(
    async () => {
      setVideo(null);
    },
    [setVideo],
  );

  const handleSubmitFormModal = useCallback(
    async (data) => {
      setVideoFormLoading(true);
      const isEditing = data?.id?.length > 0;

      setVideoFormErrors(null);
      const checkForm = validateAll(data, videoFormValidationConfig);

      // If editing, don't validate the file input
      if (isEditing) {
        delete checkForm.file;
      }

      if (checkForm && Object.keys(checkForm).length > 0) {
        setVideoFormErrors(checkForm);
        return;
      }

      try {
        const formData = new FormData();
        for (const key in data) {
          if (key === 'file') {
            formData.append('file', data.file[0]);
          } else {
            formData.append(key, data[key]);
          }
        }

        const reqData = { headers: { 'Content-Type': 'multipart/form-data' }, data: formData };

        if (isEditing) {
          await authPut(`/video/${data.id}`, reqData);
        } else {
          await authPost('/video', reqData);
        }

        const successMessage = isEditing
          ? 'Video edited successfully.'
          : 'New Video has been created.';

        toast.success(successMessage);
        handleCloseModal();
        getVideosData({});
      } catch (err) {
        console.log(err);
      }
      setVideoFormLoading(false);
    },
    [authPost, authPut],
  );
  // http://localhost:4141/video/008c3ace-0ef1-4fbd-93ce-57daa5daa9bb/forward/true
  const handleChangeOrder = useCallback(
    async ({ videoId, moveForward = true, callBack }) => {
      try {
        await authPut('/video/reorder', { data: { id: videoId, forward: moveForward } });
        if (callBack) { callBack(); }
        toast.success(`Video Order moved ${moveForward ? 'down' : 'up'} successfully.`);
      } catch (err) {
        toast.error('Error moving the Video Order Number');
        console.log(err);
      }
    },
    [authPut],
  );

  const handleOpenDeleteModal = useCallback(
    async (videoInfo) => {
      setVideoDelete(videoInfo);
    },
    [setVideoDelete],
  );

  const handleCloseDeleteModal = useCallback(
    async () => {
      setVideoDelete(null);
    },
    [setVideoDelete],
  );

  const handleSubmitDeleteModal = useCallback(
    async () => {
      try {
        await authDelete(`video/${videoDelete.id}`);

        toast.success('Deleted Successfully.');

        handleCloseDeleteModal();
        getVideosData({});
      } catch (err) {
        console.log(err);
      }
    },
    [authDelete, videoDelete],
  );

  useEffect(() => {
    getVideosData({});
  }, []);

  return {
    t,
    user,

    video,
    videosData,
    videoDataLoaded,
    videoFormLoading,

    getVideosData,
    handleOpenModalToAdd,
    handleOpenModalToEdit,
    handleCloseModal,
    handleSubmitFormModal,

    videoDelete,

    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleSubmitDeleteModal,

    videoFormErrors,
    setVideoFormErrors,

    handleChangeOrder,

    paginationData,
  };
};

export default useAdminVideo;
