import { createContext } from 'react';
import useNotifyPopperHook from './hook';

export const NotifyPopperContext = createContext({
  open: false,
  anchorEl: null,
  handleIconClick: () => {},
  handleNavigateNotifications: () => {},
});

export const NotifyPopperProvider = ({ children }) => {
  const value = useNotifyPopperHook();

  return (
    <NotifyPopperContext.Provider value={value}>
      {children}
    </NotifyPopperContext.Provider>
  );
};
