import { useContext } from 'react';
import { Typography } from '@mui/material';
import { AdminBlogContext } from '../../context';
import Modal from '../../../../../components/Modal';
import Button from '../../../../../components/Button';

const DeleteTagModal = () => {
  const {
    t,
    postTagDelete,
    handleClosePostTagDeleteModal,
    handleSubmitPostTagDeleteModal,
  } = useContext(AdminBlogContext);

  return (
    <Modal
      title="Delete Category"
      open={!!postTagDelete}
      handleClose={handleClosePostTagDeleteModal}
      width="600px"
    >
      <Typography
        fontSize="16px"
        fontWeight="bold"
        textAlign="center"
        marginBottom="8px"
      >
        {t('Are you sure you want to Delete this Tag?')}
      </Typography>
      <Typography textAlign="center" marginBottom="32px">
        {postTagDelete?.title}
      </Typography>

      <Button onClick={handleSubmitPostTagDeleteModal}>
        {t('Delete')}
      </Button>
    </Modal>
  );
};

export default DeleteTagModal;
