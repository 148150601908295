import { useContext } from 'react';
import { AppContext } from '../../../utils/store/app';

export const defaultFormValues = {};

export const formConfig = [
  {
    name: 'passType',
    label: 'Type',
    type: 'select',
    options: [],
  },
  {
    name: 'amount',
    label: 'Amount',
    type: 'number',
  },
  {
    name: 'memberId',
    label: 'Member Username/Email/ID',
    type: 'text',
  },
  {
    name: 'description',
    label: 'Description',
    type: 'textarea',
  },
  {
    name: 'transferable',
    label: 'Transferable',
    type: 'muiCheckbox',
    formGroupType: ' row-reverse',
    labelClassName: 'pt-3',
  },
];

export const getFormConfig = () => {
  const { passTypes } = useContext(AppContext);
  const opts = passTypes?.map((p) => ({ label: `${p?.name} - ${p?.points} MG Coins`, value: p?.id }));
  formConfig[0].options = opts;
  return formConfig;
};

export const formValidationConfig = {
  passType: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  amount: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  memberId: { isEmpty: false, type: 'Number', message: 'Please do not leave this field empty!' },
  description: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

export default {};
