import { useContext, useState } from 'react';
import {
  Checkbox, Container, FormControlLabel, Grid, Typography, 
} from '@mui/material';
import { NotificationsPageContext, NotificationsPageProvider } from './context';
import Layout from '../../components/CardLayout';
import Table from '../../components/Table';
import ReadNotificationModal from './components/ReadNotificationModal';

const NotificationsPage = () => {
  const {
    t,
    notificationsData,
    notificationsDataLoaded,
    columnsConfiguration,
  } = useContext(NotificationsPageContext);

  const [onlyUnread, setOnlyUnread] = useState(false);

  const handleChangeUnread = () => {
    setOnlyUnread((prev) => !prev);
  };

  return (
    <Container
      maxWidth="xl"
      className="notifications-page"
      sx={{ paddingX: { xs: 0, lg: '16px' } }}
    >
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 4,
          gap: 8,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            my: 2,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('My Notifications')}
          </span>
        </Typography>

        <Grid item mb={2} display="flex" justifyContent="center">
          <FormControlLabel
            control={(
              <Checkbox
                name="Sort"
                value={onlyUnread}
                checked={onlyUnread}
                onChange={handleChangeUnread}
                sx={{ color: 'white', fontSize: '12px !important' }}
              />
            )}
            label={(
              <div className="flex">
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 500,
                    letterSpacing: '1.2px',
                  }}
                >
                  {t('Only Unread')}
                </Typography>
              </div>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          {onlyUnread ? (
            <Table
              columns={columnsConfiguration}
              rows={notificationsData.filter((notify) => notify.unread)}
              loading={!notificationsDataLoaded}
              className="notifications-table"
            />
          ) : (
            <Table
              columns={columnsConfiguration}
              rows={notificationsData}
              loading={!notificationsDataLoaded}
              className="notifications-table"
            />
          )}

          <ReadNotificationModal />
        </Grid>
      </Layout>
    </Container>
  );
};

export default () => (
  <NotificationsPageProvider>
    <NotificationsPage />
  </NotificationsPageProvider>
);
