/* eslint-disable max-len */
import {
  Box, Container, Grid, Tab, Tabs,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Provider } from './context';
import WalletManagementAmbassador from './components/walletManagementAmbassador';
import CustomTabPanel, { a11yProps } from '../../components/Tabs';
import WalletManagementGamer from './components/walletManagementGamer';

import TransactionHistory from './components/transactionHistory';
import TransferModal from './components/transferModal';
import WithdrawModal from './components/withdrawModal';
import ConfirmationModal from './components/confirmationModal';
import CommitmentModal from './components/commitmentModal';
import Potentialbonuses from './components/Potentialbonuses';
import TransferHistory from './components/transferHistory';
import EarningsHistory from './components/earningsHistory';

function WalletsAmbassador({ isGamer }) {
  const [listTabsValue, setListTabsValue] = useState(0);

  const handleListTabChange = (_, newValue) => {
    setListTabsValue(newValue);
  };

  return (
    <Grid container spacing={2}>

      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        {/* <Banner data={{ message: 'Commissions are calculating for rank up qualified on 7/3/24, commissions will be generated 7/4/24, and will be posted by 7/5/24.' }} /> */}

        <WalletManagementAmbassador isGamer={isGamer} />
        <TransferModal />
        <WithdrawModal />
        <ConfirmationModal />
        <CommitmentModal />
      </Grid>
      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        <Potentialbonuses />
      </Grid>
      <Container
        className="ambassador-tabs"
        maxWidth="xl"
      >
        <Box>
          <Tabs
            value={listTabsValue}
            onChange={handleListTabChange}
            aria-label="basic tabs example"
            className="personals-tabs-header"
          >
            <Tab label="Transactions" {...a11yProps(0)} />
            <Tab label="Earnings" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={listTabsValue}
          index={0}
          className="ambassador-tabs-transaction"
        >
          <div className="flex">
            <Typography
              className="flex-1"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: { xs: '18px', md: '26px' },
                fontWeight: 600,
                mb: 2,
              }}
            >
              Transaction History
            </Typography>
          </div>
          <TransactionHistory />
        </CustomTabPanel>
        <CustomTabPanel
          value={listTabsValue}
          index={1}
          className="ambassador-tabs-earnings"
        >
          <EarningsHistory />
        </CustomTabPanel>
      </Container>

    </Grid>
  );
}

function WalletsGamer({ isGamer }) {
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        {/* <Banner data={{ message: 'Commissions are calculating for rank up qualified on 7/3/24, commissions will be generated 7/4/24, and will be posted by 7/5/24.' }} /> */}
        <WalletManagementGamer isGamer={isGamer} />
        <TransferModal />
        <WithdrawModal />
        <ConfirmationModal />
        <CommitmentModal />
      </Grid>

      <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
        <TransferHistory />
      </Grid>
    </Grid>
  );
}

export function WalletRedirect({ user }) {
  if (user.isAffiliate) {
    return <Navigate to="/ambassador-wallets" />;
  }

  if (user.isProduct) {
    return <Navigate to="/gamer-wallets" />;
  }

  return <Navigate to="/" />;
}

export default ({ isGamer }) => (
  <Provider isGamer={isGamer}>
    {
      isGamer ? <WalletsGamer isGamer={isGamer} /> : <WalletsAmbassador isGamer={isGamer} />
    }
  </Provider>
);
