/* eslint-disable jsx-a11y/media-has-caption */
import { Grid, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { useContext, useState } from 'react';
import LoopIcon from '@mui/icons-material/Loop';
import dayjs from 'dayjs';
import PlayVideo from '../../../../components/PlayVideo';
import { AdminVideoContext } from '../context';
import { renderHourFromSeconds } from '../../../../utils/string';

const VideoRow = ({
  video, reorder, first, last, 
}) => {
  const { t, handleOpenModalToEdit, handleOpenDeleteModal } = useContext(AdminVideoContext);

  const [videoMetaData, setVideoMetadata] = useState(null);

  return (
    <Grid
      item
      paddingY="18px"
      style={{
        boxSizing: 'border-box', position: 'relative', padding: '20px', marginBottom: '10px', marginTop: '10px', height: 'auto',
      }}
      size={4}
      xs={12}
      md={6}
      lg={4}
     
    >
      <Grid
        item
      >
        <PlayVideo
          title="Watch the video!"
          setVideoMetadata={setVideoMetadata}
          url={video?.url}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
          <Typography
            fontSize="8px"
            fontWeight="600"
            color="rgba(255, 255, 255, 0.7)"
          >
            {t('Title')}
          </Typography>
          <Typography fontSize="14px">{video?.title}</Typography>
        </Grid>
        <Grid container width="100%">
          
          <Grid item xs={6} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
            <Typography
              fontSize="8px"
              fontWeight="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              {t('Release Date')}
            </Typography>
            <Typography fontSize="14px">
              {dayjs(video?.releaseDate).format('MMM DD, YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ paddingTop: { xs: 2, lg: 0 } }}>
            <Typography
              fontSize="8px"
              fontWeight="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              {t('Duration')}
            </Typography>
            <Typography fontSize="14px">
              {videoMetaData ? (
                <>
                  {videoMetaData.elapsed > 0 && (
                  <span>
                    {renderHourFromSeconds(videoMetaData.elapsed)}
                    {' '}
                    /
                      {' '}
                  </span>
                  )}
                  <span>{renderHourFromSeconds(videoMetaData.duration || 0)}</span>
                </>
              ) : (
                <LoopIcon color="primary" className="loading-icon" />
              )}
            </Typography>
          </Grid>
          <Grid item sx={{ paddingTop: { xs: 2 }, marginBottom: '40px' }}>
            <Typography
              fontSize="8px"
              fontWeight="600"
              color="rgba(255, 255, 255, 0.7)"
            >
              {t('Description')}
            </Typography>
            <Typography fontSize="14px">{video?.description}</Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            position: 'absolute', left: 0, bottom: '0', width: '100%', 
          }}
        >
          <div className="divider" />
        </Grid>

      </Grid>
      
      <Grid
        item
        xs={12}
        md={2}
        lg={1}
        gap={4}
        display="flex"
        sx={{
          justifyContent: { xs: 'center', lg: 'flex-end' },
          paddingTop: { xs: 2, lg: 0 },
          alignItems: 'center',
        }}
        style={{ position: 'absolute', right: '5px', top: '5px' }}
      >
        <EditIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => { handleOpenModalToEdit(video); }}
        />
        <DeleteIcon
          sx={{ cursor: 'pointer', display: 'block' }}
          color="primary"
          onClick={() => { handleOpenDeleteModal(video); }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        gap={4}
        display="flex"
        sx={{
          justifyContent: 'space-between',
          paddingTop: { xs: 2, lg: 0 },
          paddingLeft: '15px',
          paddingRight: '20px',
          alignItems: 'center',
          width: '100%',
        }}
        style={{ position: 'absolute', left: '5px', bottom: '30px' }}
      >
        <ChevronLeft
          sx={{ cursor: 'pointer', display: 'block', opacity: first ? '0' : '1' }}
          color="primary"
          onClick={() => { if (!first) { reorder(false); } }}
          
        />
        <Typography
          fontSize="8px"
          fontWeight="600"
          color="rgba(255, 255, 255, 0.7)"
        >
          {t('Reorder')}
        </Typography>
        <ChevronRight
          sx={{ cursor: last ? 'none' : 'pointer', display: 'block', opacity: last ? '0' : '1' }}
          color="primary"
          onClick={() => { if (!last) { reorder(true); } }}
        />
      </Grid>
    </Grid>
  );
};

export default VideoRow;
