import { createContext } from 'react';
import useVideo from './hook';

export const VideoContext = createContext({
  t: () => {},
  user: {},

  getVideosData: () => {},

  videosData: {},
  videosDataLoaded: {},

  paginationData: {},
});

export const VideoProvider = ({ children }) => {
  const value = useVideo();
  return <VideoContext.Provider value={value}>{ children }</VideoContext.Provider>;
};
