// import { useEffect, useContext } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material';
// import { toast } from 'react-toastify';
import Navigation from './navigation';
import AppLoader from './components/AppLoader';
import { useAuthentication, useGeneral, useStyle } from './utils/store';
import ChatBot from './features/ChatBot';
import FA2Modal from './components/2FA';
// import { SocketContext } from './utils/store/socket';

function App() {
  const { muiTheme } = useStyle();
  const { configLoading } = useGeneral();
  const { authenticationLoading } = useAuthentication();
  // const socket = useContext(SocketContext);

  // useEffect(() => {
  //   if (['glek'].includes(user?.Username)) {
  //     socket.on('onUserVoted', (data) => {
  //       const { game } = data;
  //       toast.info(`User ${data.user.Username} has voted for $${game.price} game!`);
  //     });
  //   }

  //   return () => {
  //     socket.off('onUserVoted');
  //   };
  // }, [socket, user]);

  if (configLoading || authenticationLoading) {
    return <AppLoader />;
  }

  return (

    <GoogleOAuthProvider clientId={process.env.REACT_APP_FIREBASE_ID}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={muiTheme}>
          <Navigation />
          {process.env.REACT_APP_SHOW_CHATBOT === 'true' && <ChatBot />}
        </ThemeProvider>
      </LocalizationProvider>
      <FA2Modal />
    
    </GoogleOAuthProvider>

  );
}

export default App;
