import { useContext } from 'react';
import { Typography } from '@mui/material';
import { AdminVideoContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const DeleteVideoModal = () => {
  const {
    t, videoDelete, handleCloseDeleteModal, handleSubmitDeleteModal, 
  } = useContext(AdminVideoContext);

  return (
    <Modal
      title="Delete Video"
      open={!!videoDelete}
      handleClose={handleCloseDeleteModal}
      width="600px"
    >
      <Typography fontSize="16px" fontWeight="bold" textAlign="center" marginBottom="8px">
        {t('Are you sure you want to Delete this Video?')}
      </Typography>
      <Typography textAlign="center" marginBottom="32px">{videoDelete?.title}</Typography>

      <Button onClick={handleSubmitDeleteModal}>{t('Delete')}</Button>
    </Modal>
  );
};

export default DeleteVideoModal;
