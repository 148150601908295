export const flattenObrject = (obj, parentKey = '', result = {}) => {
  Object.keys(obj).forEach((key) => {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      flattenObrject(obj[key], newKey, result);
    } else {
      result[newKey] = obj[key];
    }
  });
  return result;
};

export const convertArrayToCSV = (array) => {
  if (array.length === 0) return '';
  const flattenedArray = array.map((obj) => flattenObrject(obj));
  const header = Object.keys(flattenedArray[0]).join(',');
  const rows = flattenedArray.map((obj) => Object.values(obj).map((value) => (typeof value === 'object' ? JSON.stringify(value) : value)).join(',')).join('\n');
  return `${header}\n${rows}`;
};

export const downloadCSV = (array, filename = 'data.csv') => {
  const csv = convertArrayToCSV(array);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.target = '_blank'; // Open in a new tab
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
