import { createContext } from 'react';
import useGlobalNotifyApi from './api';

export const GlobalNotifyContext = createContext({
  t: () => {},
  user: {},

  allGlobalNotifyData: [],
  allGlobalNotifyDataLoaded: false,
  singleNotifyData: {},
  deleteNotifyData: {},
  formErrors: [],
  formSubmitting: false,
  setFormErrors: () => {},
  fetchGlobalNotifyData: () => {},
  handleFormSubmitModal: () => {},
  handleFormDeleteSubmitModal: () => {},
  handleOpenModalToAdd: () => {},
  handleOpenModalToEdit: () => {},
  handleOpenModalToDelete: () => {},
  handleCloseModal: () => {},
  handleCloseDeleteModal: () => {},
});

export const GlobalNotifyProvider = ({ children }) => {
  const value = useGlobalNotifyApi();
  return <GlobalNotifyContext.Provider value={value}>{children}</GlobalNotifyContext.Provider>;
};
