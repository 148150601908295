import {
  Typography, Grid, CircularProgress, Pagination,
} from '@mui/material';
import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import dayjs from 'dayjs';
import Table from '../../../components/Table';
import Select from '../../../components/Inputs/Select';
import { Context } from '../context';
import useScreenSize from '../../../utils/hooks/useScreenSize';

export default function TransactionHistory() {
  const {
    loadingHistory,
    columns,
    historyData,
    totalPages,
    historyPage,
    fetchHistory,
    historyPageSize,
    setHistoryPageSize,
  } = useContext(Context);

  const { isMobile } = useScreenSize();

  const [filterTransactionType, setFilterTransactionType] = useState('default');
  const [filterDirection, setFilterDirection] = useState('default');

  const handlePageChange = useCallback((newNumber) => {
    const filter = {};
    filter.page = newNumber;
    filter.perPage = historyPageSize;

    if (filterTransactionType !== 'default') {
      filter.transactionType = filterTransactionType;
    }
    
    if (filterDirection !== 'default') {
      filter.direction = filterDirection;
    }
    fetchHistory(filter);
  }, [fetchHistory]);

  useEffect(() => {
    const filter = {};
    filter.page = 1;
    filter.perPage = historyPageSize;
    
    if (filterTransactionType !== 'default') {
      filter.transactionType = filterTransactionType;
    }
    
    if (filterDirection !== 'default') {
      filter.direction = filterDirection;
    }
    fetchHistory(filter);
  }, [filterTransactionType, filterDirection, historyPageSize]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
          marginBottom: '1.5rem',
        }}
        className="wallet-transaction-filters"
      >
        <div className="flex" style={{ gap: '1.5rem' }}>
          <Select
            className="transactions-filter-select"
            placeholder="Any type"
            size="sm"
            value={filterTransactionType}
            onChange={(e) => setFilterTransactionType(e)}
            options={[
              { value: 'Received daily comm', label: 'Daily Pay' },
              { value: 'Match bonus for', label: 'Matching Bonus' },
              { value: 'Redeemed for ', label: 'Packs' },
              { value: 'Withdraw', label: 'Withdrawals' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
          <Select
            className="transactions-filter-select"
            placeholder="Any direction"
            size="sm"
            value={filterDirection}
            onChange={(e) => setFilterDirection(e)}
            options={[
              { value: 'receive', label: 'All Credits' },
              { value: 'deducted', label: 'All Debits' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
        </div>
        <div className="flex">
          <Select
            className="transactions-filter-select"
            placeholder="Pagination"
            size="sm"
            value={`${historyPageSize}`}
            onChange={(e) => setHistoryPageSize(Number(e) > 0 ? Number(e) : 10)}
            options={[
              { value: '10', label: '10 per Page' },
              { value: '20', label: '20 per Page' },
              { value: '30', label: '30 per Page' },
              { value: '40', label: '40 per Page' },
              { value: '50', label: '50 per Page' },
            ]}
            sx={{ paddingRight: '1rem' }}
          />
        </div>
      </Grid>
      {loadingHistory ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Grid container rowSpacing={2}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="stretch"
            className="wallet-transactions-history"
          >
            {isMobile ? (
              historyData.map((data, idx) => (
                <Grid
                  key={idx}
                  container
                  borderBottom="1px solid rgba(255, 255, 255, 0.1)"
                  paddingY="24px"
                >
                  <Grid item xs={6} marginBottom="6px">
                    <Typography
                      fontSize="8px"
                      fontWeight="600"
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      Amount
                    </Typography>
                    <Typography fontSize="14px">
                      <span className={data.direction}>
                        {`$${Number(data.amount).toLocaleString('en-us')}`}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} marginBottom="6px">
                    <Typography
                      fontSize="8px"
                      fontWeight="600"
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      Date
                    </Typography>
                    <Typography fontSize="14px">
                      {dayjs(data.createdAt).format('MMM DD, YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize="8px"
                      fontWeight="600"
                      color="rgba(255, 255, 255, 0.7)"
                    >
                      Description
                    </Typography>
                    <Typography fontSize="14px">
                      {data.description}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Table
                loading={loadingHistory}
                columns={columns}
                rows={historyData}
                className="table mb-4"
              />
            )}
          </Grid>
          {totalPages > 1 && (
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="flex-end"
              className="custom-pagination"
            >
              <Pagination
                count={totalPages}
                page={historyPage}
                onChange={(val, page) => {
                  handlePageChange(page);
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
}
