/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import {
  Grid, CircularProgress, Pagination, Box,
  Tabs,
  Tab,
} from '@mui/material';
import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import Layout from '../../../components/CardLayout';
import Table from '../../../components/Table';
import { Context } from '../context';
import CustomTabPanel, { a11yProps } from '../../../components/Tabs';
import Select from '../../../components/Inputs/Select';

export default function TransferHistory() {
  const {
    loadingHistory,
    columns,
    historyData,
    totalPages,
    historyPage,
    fetchHistory,
    transferTabsValue,
    handleTabTransfersChange,
    historyPageSize,
    setHistoryPageSize,
  } = useContext(Context);
  
  const [filterTransactionType, setFilterTransactionType] = useState('default');
  const [filterDirection, setFilterDirection] = useState('default');
  
  const handlePageChange = useCallback((newNumber) => {
    const filter = {};
    filter.page = newNumber;
    filter.perPage = historyPageSize;

    if (filterTransactionType !== 'default') {
      filter.type = filterTransactionType;
    }
    
    if (filterDirection !== 'default') {
      filter.direction = filterDirection;
    }
    fetchHistory(filter);
  }, [fetchHistory]);
  
  useEffect(() => {
    const filter = {};
    filter.page = 1;
    filter.perPage = historyPageSize;
    
    if (filterTransactionType !== 'default') {
      filter.type = filterTransactionType;
    }
    
    if (filterDirection !== 'default') {
      filter.direction = filterDirection;
    }
    fetchHistory(filter);
  }, [filterTransactionType, filterDirection, historyPageSize]);

  return (
    <Layout maxWidth="xl">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={transferTabsValue} onChange={handleTabTransfersChange} aria-label="basic tabs example">
          <Tab label="Transactions History" {...a11yProps(0)} />
          {/* <Tab label="Transfers History" {...a11yProps(1)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={transferTabsValue} index={0}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            marginBottom: '1.5rem',
          }}
          className="wallet-transfer-filters"
        >
          <div className="flex" style={{ gap: '1.5rem' }}>
            <Select
              className="transfers-filter-select"
              placeholder="Any type"
              size="sm"
              value={filterTransactionType}
              onChange={(e) => setFilterTransactionType(e)}
              options={[
                { value: 'Coins', label: 'MG Coins' },
                { value: 'Tokens', label: 'MG Tokens' },
              ]}
              sx={{ paddingRight: '1rem' }}
            />
            <Select
              className="transfers-filter-select"
              placeholder="Any direction"
              size="sm"
              value={filterDirection}
              onChange={(e) => setFilterDirection(e)}
              options={[
                { value: 'receive', label: 'All Credits' },
                { value: 'deducted', label: 'All Debits' },
              ]}
              sx={{ paddingRight: '1rem' }}
            />
          </div>
          <div className="flex">
            <Select
              className="transfers-filter-select"
              placeholder="Pagination"
              size="sm"
              value={`${historyPageSize}`}
              onChange={(e) => setHistoryPageSize(Number(e) > 0 ? Number(e) : 10)}
              options={[
                { value: '10', label: '10 per Page' },
                { value: '20', label: '20 per Page' },
                { value: '30', label: '30 per Page' },
                { value: '40', label: '40 per Page' },
                { value: '50', label: '50 per Page' },
              ]}
              sx={{ paddingRight: '1rem' }}
            />
          </div>
        </Grid>
        <Grid item container xs>
          {loadingHistory ? (
            <div className="flex justify-center">
              <CircularProgress />
            </div>
          ) : (
            <Grid item container xs rowSpacing={2} className="wallet-transactions-history">
              <Grid
                item
                container
                xs={12}
                direction="column"
                justifyContent="center"
                alignItems="stretch"
              >
                <Table
                  loading={loadingHistory}
                  columns={columns}
                  rows={historyData}
                  className="table mb-4"
                />
              </Grid>
              {totalPages > 1 && (
                <Grid item container xs={12} justifyContent="flex-end">
                  <Pagination
                    count={totalPages}
                    page={historyPage}
                    onChange={(val, page) => {
                      handlePageChange(page);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </CustomTabPanel>
    </Layout>
  );
}
