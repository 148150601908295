import { createContext } from 'react';
import useAdminVideo from './hook';

export const AdminVideoContext = createContext({
  t: () => {},
  user: {},

  video: {},
  videosData: {},
  videosDataLoaded: false,
  videoFormLoading: false,

  getVideosData: () => {},
  handleOpenModalToAdd: () => {},
  handleOpenModalToEdit: () => {},
  handleCloseModal: () => {},
  handleSubmitFormModal: () => {},

  videoDelete: {},

  handleOpenDeleteModal: () => {},
  handleCloseDeleteModal: () => {},
  handleSubmitDeleteModal: () => {},

  videoFormErrors: {},
  setVideoFormErrors: () => {},

  paginationData: {},
});

export const AdminVideoProvider = ({ children }) => {
  const value = useAdminVideo();
  return <AdminVideoContext.Provider value={value}>{ children }</AdminVideoContext.Provider>;
};
