import { Typography } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../context';

const CountdownToMidnight = ({ game }) => {
  const { refreshMemeCoins } = useContext(Context);
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [timeStarted, setTimeStarted] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [loading, setLoading] = useState(true);

  const calculateGameTimes = () => {
    const now = new Date();

    // Calculate the Game Time Left
    const gameEndDate = new Date(game?.endDate);

    const timeToEnd = gameEndDate - now;

    const remainingTime = (timeToEnd > 0) ? {
      hours: Math.floor((timeToEnd / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((timeToEnd / 1000 / 60) % 60),
      seconds: Math.floor((timeToEnd / 1000) % 60),
    } : {};

    setTimeLeft(remainingTime);

    // Calculate the Game Time Started
    const gameStartDate = new Date(game?.startDate);
    const gameTimeStarted = now - gameStartDate;

    const startedTime = (gameTimeStarted > 0) ? {
      hours: Math.floor((gameTimeStarted / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((gameTimeStarted / 1000 / 60) % 60),
      seconds: Math.floor((gameTimeStarted / 1000) % 60),
    } : {};

    setTimeStarted(startedTime);
  };

  useEffect(() => {
    const initializeCountdown = async () => {
      calculateGameTimes();
      setLoading(false);
    };

    initializeCountdown();

    const timer = setInterval(() => {
      calculateGameTimes();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (time) => {
    const { hours, minutes, seconds } = time;

    if (!hours && !minutes && !seconds) {
      return 'Ended';
    }

    if ((hours === 23 && minutes === 59 && seconds === 59)
      || (hours === 0 && minutes === 0 && seconds === 0)) {
      refreshMemeCoins();
    }
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  if (loading) {
    return (
      <Typography component="h2">
        Loading...
      </Typography>
    );
  }

  return (
    <Typography className="countdown" component="h2">
      <span className="text">
        Time Since Game Started/Time to Close: &nbsp;
      </span>
      <div className="primary-text-gradient">
        {` ${formatTime(timeStarted)}/${formatTime(timeLeft)}`}
      </div>
    </Typography>
  );
};

export default CountdownToMidnight;
