/* eslint-disable indent */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import CardChip from '../CardChip';
import CoinsChart from '../CoinsChart';
import { renderNumber } from '../../../../../utils/number';
import { renderDateForHumans } from '../../../../../utils/string';
import Button from '../../../../../components/Button';

export default function OthersCont({
  game,
  chosenMemeCoin,
  coins,
  isYesterday,
  percentageChange,
  isTop10,
  isMobile,
  leaderboardRank,
  leader,
  yesterdayChartData,
  yesterdayChartLegend,
  currentVoteIndex,
  setCurrentVoteIndex,
  votes,
}) {
  const potentialTokens = game.price * game.maxMultiplier;
  const isWinner = chosenMemeCoin?.memeCoinsId === game?.scheduleGameId?.winningCoin;

  return (
    <div className="today-extra flex flex-column p-12">
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xl: 'column',
            lg: 'column',
            md: 'column',
            sm: 'row',
            xs: 'row',
          },
        }}
      >
        {!isYesterday && (
          <div className="potential-points mb-12">
            <div className="left">
              <span>Potential MG Tokens</span>
            </div>
            <div className="right">
              <span>
                {renderNumber(potentialTokens)}
              </span>
            </div>
          </div>          
        )}
        {isYesterday && Object.entries(votes).length >= 1 && Object.entries(votes).map(([voteId, vote], voteIndex) => (
          <div
            className={`potential-points mb-12${voteIndex === currentVoteIndex ? '' : ' hidden'}`}
            key={voteId}
          >
            <div className="left">
              <span>{vote.token > 0 ? 'MG Tokens Earned' : 'MG Coins Refunded'}</span>
            </div>
            <div className="right">
              <span>
                {renderNumber(vote.token > 0 ? vote.token : vote.coins)}
              </span>
            </div>
          </div>
        ))}

        <CoinsChart
          isYesterday={isYesterday}
          game={game}
          coins={coins}
          yesterdayChartData={yesterdayChartData}
          yesterdayChartLegend={yesterdayChartLegend}
        />
      </Box>
      <div className="data">
        <div
          className="lead-row-item"
          style={isMobile ? { padding: '5px 0' } : {}}
        >
          <div className="item">
            <span>Leading Player:</span>
            {leader ? (
              <span className="primary-color emoji">
                <img
                  className="leader-coin-image"
                  src={leader.coinImage}
                  alt=""
                  style={{ borderRadius: '5px' }}
                />
                {`${leader?.image} ${leader?.username} (${leader?.change?.toFixed(2)}%)`}
              </span>
            ) : (
              '-'
            )}
          </div>
        </div>
      </div>
      {(chosenMemeCoin && !isYesterday) || (isWinner && isYesterday) ? (
        <div className="data">
          {isMobile && (
            <div className="row-item" style={{ padding: '5px 0' }}>
              {!isYesterday && (
                <div className="item">
                  <span>Potential MG Tokens</span>
                  <span className="primary-text-gradient">
                    {renderNumber(potentialTokens)}
                  </span>
                </div>
              )}
              {isYesterday && Object.entries(votes).length >= 1 && Object.entries(votes).map(([voteId, vote], voteIndex) => (
                <div className={`item${voteIndex === currentVoteIndex ? '' : ' hidden'}`} key={voteId}>
                  <span>{vote.token > 0 ? 'MG Tokens Earned' : 'MG Coins Refunded'}</span>
                  <span className="primary-text-gradient">
                    {renderNumber(vote.token > 0 ? vote.token : vote.coins)}
                  </span>
                </div>
              ))}
            </div>
          )}
          <div
            className="row-item "
            style={isMobile ? { padding: '5px 0' } : {}}
          >
            <div
              className="item best-change"
              style={{ '--dynamic-top': `${25 + (currentVoteIndex * 55)}px` }}
            >
              <span>My Best % Change:</span>
              <span className="primary-text-gradient">
                {`${renderNumber(percentageChange.toFixed(2))}%`}
              </span>
            </div>

            <div className="votes-items">
              {Object.entries(votes).length >= 1 && Object.entries(votes).map(([voteId, vote], voteIndex) => (
                <Button
                  type="button"
                  size="sm"
                  className={`item item--sol ${currentVoteIndex === voteIndex ? 'current' : ''}`}
                  bordered={!vote?.stats?.locked}
                  key={voteId}
                  onClick={() => setCurrentVoteIndex(voteIndex)}
                >
                  <span>
                    {`Game ${voteIndex + 1} : `}
                    <span style={{ fontWeight: '700' }}>
                      {`${renderNumber(vote?.stats?.change?.toFixed(2))}% `}
                    </span>
                    <span style={{ fontSize: '14px', opacity: 0.7 }}>
                      {`${renderDateForHumans(vote.createdAt)}`}
                    </span>
                  </span>

                  {vote?.stats?.locked && (
                    <span style={{ fontSize: '8px', position: 'relative', top: '0px' }}>
                      <LockIcon style={{ fontSize: '18px' }} />
                    </span>
                  )}
                  <div className="chip">
                    <CardChip
                      isGrowing={(vote?.stats?.rankingPercentage || game?.rankingPercentage) <= 10}
                      noIcon={(vote?.stats?.rankingPercentage || game?.rankingPercentage) > 10}
                      className="with-border"
                    >
                      {`${(vote?.stats?.rankingPercentage || game?.rankingPercentage) || 0}%`}
                    </CardChip>
                  </div>
                </Button>
              ))}
            </div>
          </div>
          <div
            className="row-item"
            style={isMobile ? { padding: '5px 0' } : {}}
          >
            <div className="item">
              <span>Leaderboard Rank:</span>
              <div className="meme-coin-data">
                <div className="data-cont">
                  <div className="chip">
                    <CardChip
                      isGrowing={leaderboardRank <= 10}
                      noIcon={leaderboardRank > 10}
                      className="with-border"
                    >
                      {`${parseInt(leaderboardRank, 10)}%`}
                    </CardChip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        isMobile
        && isYesterday && (
          <div className="data">
            <div className="row-item" style={{ padding: '5px 0' }}>
              {Object.entries(votes).length >= 1 && Object.entries(votes).map(([voteId, vote], voteIndex) => (
                <div className={`item${voteIndex === currentVoteIndex ? '' : ' hidden'}`} key={voteId}>
                  <span>{vote.token > 0 ? 'MG Tokens Earned' : 'MG Coins Refunded'}</span>
                  <span className="primary-text-gradient">
                    {renderNumber(vote.token > 0 ? vote.token : vote.coins)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </div>
  );
}
