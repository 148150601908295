import { useNavigate } from 'react-router-dom';
import KeyboardDoubleArrowUpButton from '@mui/icons-material/KeyboardDoubleArrowUp';
import { useContext } from 'react';
import {
  Container, Grid, Typography, 
} from '@mui/material';
import { FAQContext, FAQProvider } from './context';
import Layout from '../../../components/CardLayout';
import ComponentLoader from '../../../components/ComponentLoader';
import FAQAccordion from './components/FAQAccordion';

const FAQPage = () => {
  const {
    t,
    user,

    faqsData,
    faqsDataLoaded,

    sortAsc,
    handleChangeSortDirection,
  } = useContext(FAQContext);
  const navigate = useNavigate();

  if (!user) {
    navigate('/landing');
  }

  return (
    <Container
      maxWidth="xl"
      className="faq-page"
      sx={{ paddingX: { xs: 0, lg: '16px' } }}
    >
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 4,
          gap: 8,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mt: 2,
            mb: 4,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('FAQ')}
          </span>
        </Typography>

        <Grid item mb={3} display="flex" justifyContent="flex-end">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              letterSpacing: '1.2px',
              cursor: 'pointer',
              display: 'inline-flex',
              justifyContent: 'center',
              gap: '6px',
            }}
            onClick={handleChangeSortDirection}
            variant="button"
          >
            <span style={{ paddingTop: '1px' }}>{t('Sort Categories')}</span>
            <KeyboardDoubleArrowUpButton
              color="secondary"
              sx={{ rotate: sortAsc ? 0 : '180deg' }}
            />
          </Typography>
        </Grid>

        {!faqsDataLoaded ? <ComponentLoader /> : (
          faqsData.length > 0 ? (
            faqsData.sort((a, b) => (sortAsc ? a.category.localeCompare(b.category) : b.category.localeCompare(a.category)))
              .map((item, idx) => (
                <Grid item xs={12} key={idx} marginBottom={5}>
                  <Grid item xs={12} paddingBottom={2}>
                    <div className="divider" />
                  </Grid>
                  <Typography
                    style={{ textTransform: 'uppercase' }}
                    fontWeight={600}
                    marginBottom="4px"
                  >
                    {item.category}
                  </Typography>
                  {item.faqs.map((faq, index) => <FAQAccordion key={index} faq={faq} />)}
                </Grid>
              ))
          ) : (
            <Typography fontSize="16px" textAlign="center">
              {t('Stay tunned.')}
            </Typography>
          )
        )}

      </Layout>
    </Container>
  );
};

export default () => (
  <FAQProvider>
    <FAQPage />
  </FAQProvider>
);
