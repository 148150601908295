export const notifyFormConfig = [
  {
    name: 'title',
    type: 'text',
    label: 'Title',
    placeholder: 'Notify title',
    required: true,
  },
  {
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Type your message',
    required: true,
    rows: 6,
  },
  {
    name: 'role',
    type: 'select',
    options: [
      { value: 'empty', label: 'Empty' },
      { value: 'ambassador', label: 'Ambassadors' },
      { value: 'gamer', label: 'Gamers' },
    ],
    label: 'To Specific Role',
    showOnCondition: false,
    showOnConditionField: 'specific_user',
  },
  {
    name: 'specific_user',
    type: 'muiCheckbox',
    label: 'To Specific User',
    checked: true,
  },
  {
    name: 'user',
    label: 'Select User',
    type: 'autocomplete',
    endPoint: '/users',
    mapper: (val) => ({ label: `${val?.Name} ${val?.Surname} (${val?.Username})`, value: val.SID }),
    showOnCondition: true,
    showOnConditionField: 'specific_user',
  },
  {
    name: 'active',
    type: 'muiCheckbox',
    label: 'Active',
    checked: false,
  },
];

export const notifyFormValidationConfig = {
  title: { message: 'Title is required' },
  message: { message: 'Message is required' },
};
