/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Layout from '../../../components/CardLayout';
import { Context } from '../context';
import ComponentLoader from '../../../components/ComponentLoader';
import PrevNextButtons from './prevNext';

export default function Events() {
  const {
    events, eventsIndex, eventChange, eventsLoading,
  } = useContext(Context);
  const { t } = useTranslation();
  return (

    <Layout style={{ height: '100%' }} maxWidth="100%">
      <Grid container justifyContent="space-between" rowSpacing={2}>
        <Grid
          item
          xl={6}
          md={12}
          lg={6}
          sm={12}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: {
              xl: 'start', lg: 'start', md: 'center', sm: 'center', xs: 'center',
            },
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>{t('Upcoming events:')}</Typography>
        </Grid>

        <Grid
          item
          xl={2}
          md={6}
          lg={2}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: {
              xl: 'start', lg: 'start', md: 'start', sm: 'start', xs: 'center', alignItems: 'center',
            },
          }}
        >
          {
            eventsLoading ? (
              <span>{`0 ${t('of')} 0`}</span>
            ) : (
              <span>{`${eventsIndex + 1} ${t('of')} ${events.length}`}</span>
            )
          }
        </Grid>

        <Grid
          item
          xl={4}
          md={6}
          lg={4}
          sm={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: {
              xl: 'end', lg: 'end', md: 'end', sm: 'end', xs: 'center',
            },
          }}
        >
          <PrevNextButtons
            onClickPrev={() => {
              eventChange(eventsIndex - 1);
            }}
            onClickNext={() => {
              eventChange(eventsIndex + 1);
            }}
            disabledNext={events.length <= 1}
            disabledPrev={events.length <= 1}
          />

        </Grid>

        <Grid item xl={12} md={12} lg={12} sm={12} xs={12} sx={{ marginTop: '-18px' }}>
          <div className="divider" />
        </Grid>

        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
          <div style={{ position: 'relative' }}>
            {
              eventsLoading ? <ComponentLoader /> : (
                <>
                  <Typography sx={{ fontSize: '15px' }} color="primary">
                    {dayjs(events[eventsIndex]?.date)?.format('MMM DD, YYYY')}
                  </Typography>

                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>
                    {events[eventsIndex]?.title}
                  </Typography>

                  <Typography sx={{ fontSize: '15px' }}>
                    <span dangerouslySetInnerHTML={{ __html: events[eventsIndex]?.description }} />
                  </Typography>
                </>
              )
            }
          </div>
        </Grid>
      </Grid>
    </Layout>

  );
}
