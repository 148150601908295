import {
  useState, useCallback, useEffect,
} from 'react';
import useApi from '../../../utils/api';

export default function useMyCoinPicks() {
  const { authGet } = useApi();
  const [rows, setRows] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: 'lastVotedAt',
      sort: 'desc',
    },
  ]);

  const getMyPicks = useCallback(
    async (page = 1) => {
      setLoading(true);
      try {
        const sortField = `${sortModel ? sortModel[0]?.field : 'lastVotedAt'}`;
        const dir = sortModel[0]?.sort === 'asc' ? '1' : '-1';
        const res = await authGet('/schedule-game/my-picks', {
          params: {
            page,
            perPage: 20,
            orderBy: { [sortField]: dir },
          },
        });

        setRows(res?.data);
        setPaginationData({
          totalItems: res.total,
          total: Math.ceil(res.total / 20),
          page,
        });
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [authGet, paginationData, setLoading, sortModel],
  );

  useEffect(() => {
    getMyPicks();
  }, [sortModel]);

  return {
    getMyPicks,
    rows,
    setRows,
    sortModel,
    setSortModel,
    paginationData,
    setPaginationData,
    loading,
    setLoading,
  };
}
