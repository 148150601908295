import { useContext } from 'react';
import { Typography } from '@mui/material';
import { AdminBlogContext } from '../context';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

const DeletePostModal = () => {
  const {
    t,
    postDelete,
    handleDeletePostModalSubmit,
    handleCloseDeletePostModal,
  } = useContext(AdminBlogContext);
  return (
    <Modal
      title="Delete Blog Post"
      open={!!postDelete}
      handleClose={handleCloseDeletePostModal}
      width="600px"
    >
      <Typography fontSize="16px" fontWeight="bold" textAlign="center" marginBottom="8px">
        {t('Are you sure you want to Delete this Post?')}
      </Typography>
      <Typography textAlign="center" marginBottom="32px">
        {postDelete?.title}
      </Typography>

      <Button onClick={handleDeletePostModalSubmit}>
        {t('Delete')}
      </Button>
    </Modal>
  );
};

export default DeletePostModal;
