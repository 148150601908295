/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef } from 'react';

const PlayVideo = ({ url = null, setVideoMetadata = null }) => {
  const videoUrl = url || process.env.REACT_APP_PODCAST_AUDIO_URL;

  const videoRef = useRef(null);

  const saveAudioMetadata = (videoDuration) => {
    if (setVideoMetadata) {
      setVideoMetadata({
        duration: videoDuration,
      });
    }
  };

  useEffect(() => {
    const video = videoRef.current;
   
    const onLoadedMetadata = () => saveAudioMetadata(video.duration);
    if (video) {
      video.addEventListener('loadedmetadata', onLoadedMetadata);
    }

    return () => {
      video.addEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, []);

  return (
    <div style={{
      width: '100%', paddingBottom: '56.25%', position: 'relative', marginBottom: '12px', 
    }}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        preload="metadata"
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)', 
        }}
        controls
      />
    
    </div>
    
  );
};

export default PlayVideo;
