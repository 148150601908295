/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';
import dayjs from 'dayjs';

const renderAdminInfo = (userId, sponsorId) => (
  <>
    <Typography sx={{ fontSize: '12px', color: 'gray' }}>
      {`ID: ${userId}`}
    </Typography>
    <Typography sx={{ fontSize: '12px', color: 'gray' }}>
      {`PID: ${sponsorId}`}
    </Typography>
  </>

);

export default function Node({
  username, displayName, dateExpiring, userId, sponsorId,
}) {
  let ambassadorDisplayName = '';
  if (displayName) {
    const dn = displayName.split(' ');
    ambassadorDisplayName = `${dn[0]} ${dn[1]?.substring(1, 0)}.`;
  }

  return (
    <div style={{ position: 'relative' }}>
      <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>{username}</Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>{ambassadorDisplayName}</Typography>
      <Typography sx={{ fontSize: '12px', color: 'gray' }}>
        {`${dayjs(dateExpiring).tz('UTC').format('MMM DD, YYYY')}`}
      </Typography>
      {/* {renderAdminInfo(userId, sponsorId)} */}
    </div>
  );
}
