/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-regex-literals */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable space-before-blocks */
/* eslint-disable space-infix-ops */
/* eslint-disable no-param-reassign */
import {
  useState, useCallback, useEffect, 
} from 'react';
import useApi from '../../api';
import { passTypesColors } from '../../../features/Admin/PassTypes/config';
import { getUserObject } from '../../storage';
import { useAuthentication } from '..';

/**
 * Hook where AppContext functionallity is created
 */
export default function useAppStore() {
  const { authGet } = useApi();
  const [passTypes, setPassTypes] = useState([]);
  const [purchasablePassTypes, setPurchasablePassTypes] = useState([]);
  const [loadingPurchasablePassTypes, setLoadingPurchasablePassTypes] = useState(false);
  const [packages, setPackages] = useState([]);
  const [loadingPassTypes, setLoadingPassTypes] = useState(false); 
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [ranks, setRanks] = useState([]);
  const [loadingRanks, setLoadingRanks] = useState(false);
  const { user } = useAuthentication();
  const [globalNotificationsCount, setGlobalNotificationsCount] = useState(0);

  const getPassTypes = useCallback(async () => {
    try {
      setLoadingPassTypes(true);
      let uri = '/pass-types';
      // eslint-disable-next-line no-shadow
      const _user = await getUserObject();
      if (_user) {
        uri = `${uri}?user=${_user.SID}`;
      }
      const res = await authGet(uri);
      const items = res.map((pt, index) => ({ ...pt, color: passTypesColors[index] }));
      setPassTypes(items);
    } catch (err) {
      console.log(err);
    }
    setLoadingPassTypes(false);
  }, [authGet, setLoadingPassTypes, setPassTypes]);

  const getPurchasablePassTypes = useCallback(async () => {
    try {
      setLoadingPurchasablePassTypes(true);
      const _user = await getUserObject();
      const uri = '/pass-types';
      const res = await authGet(uri, {
        params: { 
          active: true, 
          purchasable: true,
          ...(_user && { user: _user.SID }),
        }, 
      });
      const items = res.map((pt, index) => ({ ...pt, color: passTypesColors[index] }));
      setPurchasablePassTypes(items);
    } catch (err) {
      console.log(err);
    }
    setLoadingPurchasablePassTypes(false);
  }, [authGet, setPurchasablePassTypes, setPurchasablePassTypes]);

  const updatePassTypes = useCallback(
    (passType) => {
      setLoadingPassTypes(true);
      const pts = passTypes.map((type) => (type.id === passType.id
        ? { ...type, ...passType } : type));
      setPassTypes(pts);
      setLoadingPassTypes(false);
      getPassTypes();
    },
    [setLoadingPassTypes, setPassTypes, passTypes],
  );

  const createPassTypes = useCallback(
    (passType) => {
      setLoadingPassTypes(true);
      passTypes.push(passType);
      setPassTypes(passTypes);
      setLoadingPassTypes(false);
    },
    [setLoadingPassTypes, setPassTypes, passTypes],
  );

  const updatePackage = useCallback((pack) => {
    setLoadingPackages(true);
    const packs = packages.map((p) => (p.id === pack.id ? { ...p, ...pack } : p));
    setPackages(packs);
    setLoadingPackages(false);
  }, [setLoadingPackages, setPackages, packages]);

  const createPackage = useCallback((pack) => {
    setLoadingPackages(true);
    packages.push(pack);
    setPackages(packages);
    setLoadingPackages(false);
  }, [setLoadingPackages, setPackages, packages]);

  const getPackages = useCallback(async () => {
    try {
      setLoadingPackages(true);
      const res = await authGet('/packages');
      setPackages(res);
    } catch (err) {
      console.log(err);
    }
    setLoadingPackages(false);
  }, []);

  const getRanks = useCallback(async () => {
    try {
      setLoadingRanks(true);
      const res = await authGet('/ranks');
      setRanks(res);
    } catch (err) {
      console.log('---get ranks---', err);
    }
    setLoadingRanks(false);
  }, [authGet, setLoadingRanks, setRanks]);

  useEffect(() => {
    // if (user?.Permissions?.IsAdmin || user?.Permissions?.IsSuperAdmin) {
    if (passTypes.length === 0) {
      getPassTypes();
    }
    if (packages.length === 0) {
      getPackages();
    }
    if (purchasablePassTypes.length === 0) {
      getPurchasablePassTypes();
    }
    if (ranks.length === 0) {
      getRanks();
    }
    // }
  }, [user]);

  const updateRanks = useCallback(
    (rank) => {
      setLoadingRanks(true);
      
      setRanks(ranks.map((i) => {
        if (i.id===rank.id){
          return rank;
        }
        return i;
      }));
      setLoadingRanks(false);
    },
    [setLoadingRanks, setRanks, ranks],
  );

  const createRanks = useCallback(
    (rank) => {
      setLoadingRanks(true);
      ranks.push(rank);
      setRanks(ranks);
      setLoadingRanks(false);
    },
    [setLoadingRanks, setRanks, ranks],
  );

  const fetchGlobalNotificationsCount = useCallback(
    async () => {
      const response = await authGet('global-notify/unread');

      const count = response?.total || 0;

      setGlobalNotificationsCount(count);
    },
    [authGet],
  );

  return {
    getPassTypes,
    updatePassTypes,
    createPassTypes,
    passTypes,
    loadingPassTypes,
    getPackages,
    updatePackage,
    createPackage,
    packages,
    loadingPackages,
    getRanks,
    ranks,
    loadingRanks,
    createRanks,
    updateRanks,
    getPurchasablePassTypes,
    purchasablePassTypes,
    loadingPurchasablePassTypes,
    fetchGlobalNotificationsCount,
    globalNotificationsCount,
  };
}
