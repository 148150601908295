import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AuthenticationContext } from '../../../../utils/store/authentication';
import useApi from '../../../../utils/api';

const PAGE_SIZE = 25;

const useVideo = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const { authGet } = useApi();
  const [videosData, setVideosData] = useState([]);
  const [videosDataLoaded, setVideosDataLoaded] = useState(false);
  const [paginationData, setPaginationData] = useState();

  const getVideosData = useCallback(
    async ({ page = 1 }) => {
      try {
        setVideosDataLoaded(false);
        const response = await authGet('/video', {
          params: { page, perPage: PAGE_SIZE, published: true },
        });
        setVideosData(response.data || []);

        setPaginationData({
          totalVideos: response.total,
          total: Math.ceil(response.total / PAGE_SIZE),
          page,
        });
      } catch (err) {
        console.log(err);
        toast.error('Error fetching video Data');
      }
      setVideosDataLoaded(true);
    },
    [authGet],
  );

  useEffect(() => {
    getVideosData({});
  }, []);

  return {
    t,
    user,

    getVideosData,

    videosData,
    videosDataLoaded,

    paginationData,
  };
};

export default useVideo;
