import { useContext } from 'react';
import Modal from '../../../../components/Modal';
import { AdminVideoContext } from '../context';
import Form from '../../../../components/Form';
import { videoFormConfig } from '../config';

const AddEditVideoModal = () => {
  const {
    t,
    video,
    handleCloseModal,
    handleSubmitFormModal,
    videoFormLoading,

    videoFormErrors,
    setVideoFormErrors,
  } = useContext(AdminVideoContext);

  const isEdit = video?.id?.length > 0;

  return (
    <Modal
      title={!isEdit ? t('Add New Video') : t('Edit Video')}
      open={!!video}
      handleClose={handleCloseModal}
      width="650px"
    >
      <Form
        config={videoFormConfig}
        errors={videoFormErrors}
        setErrors={setVideoFormErrors}
        onSubmit={handleSubmitFormModal}
        defaultFormValue={video}
        formLoading={videoFormLoading}
        buttonType="button"
        buttonStyle={{ width: '100%' }}
      />
    </Modal>
  );
};

export default AddEditVideoModal;
