import dayjs from 'dayjs';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',

  cellClassName: () => 'first-column',
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t) => [
  {
    field: 'PUID',
    headerName: t('Order ID'),
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ value }) => value,
  },
  {
    field: 'passType',
    headerName: t('Coin Pack'),
    minWidth: 350,
    ...defaultConfig,
    renderCell: ({ row }) => row?.passType?.name,
  },
  {
    field: 'Status',
    headerName: t('Status'),
    minWidth: 200,
    ...defaultConfig,
  },
  {
    field: 'units',
    headerName: t('Total Passes'),
    minWidth: 130,
    ...defaultConfig,
  },
  {
    field: 'priceTotal',
    minWidth: 130,
    headerName: `${t('Price')}`,
    ...defaultConfig,
    renderCell: ({ value }) => `$${value}`,
  },
  {
    field: 'createdAt',
    headerName: t('Date'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).format('MMM DD, YYYY'),
  },
];

export default columnsConfig;
