const Price = ({ value, dollarSign }) => {
  const n = Number(value).toString();
  if (n.includes('e-')) {
    const [a, b] = n.split('e-');
    const start = '0.0';
    const zeros = '0'.repeat(+b).toString();
    const nums = a.split('.').join('');
    const zLen = zeros.length - 1;
    return (
      <span style={{ position: 'relative' }}>
        {dollarSign && '$'}
        {start}
        <sub style={{ fontSize: '11px', position: 'relative', top: '3px' }}>{zLen}</sub>
        {nums}
      </span>
    );
  }
  return (
    dollarSign ? `$${n}` : n
  );
};
export default Price;
