import { useContext, useEffect } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { AdminBlogContext } from '../../context';
import ComponentLoader from '../../../../../components/ComponentLoader';

const PostTagCheckbox = ({ selectedTags = [] }) => {
  const {
    t,
    postTagsChecks,
    postTagsChecksLoaded,
    setPostTagsChecks,
    createPostTagsChecks,
  } = useContext(AdminBlogContext);
  
  useEffect(() => {
    createPostTagsChecks(selectedTags);
  }, []);

  const handleChangeCheckValue = (checked, value) => {
    const checks = postTagsChecks.map((tag) => (tag.value === value ? { ...tag, checked } : tag));
    setPostTagsChecks(checks);
  };

  return postTagsChecksLoaded ? (
    postTagsChecks.length > 0 ? (
      postTagsChecks.map((check) => (
        <FormControlLabel
          key={check.value}
          sx={{ fontSize: '12px', alignItems: 'flex-start', width: '100%' }}
          control={(
            <Checkbox
              checked={check.checked}
              onChange={(e) => handleChangeCheckValue(e.target.checked, check.value)}
            />
          )}
          label={(
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 500,
                letterSpacing: '1.2px',
                mt: '10px',
              }}
            >
              {t(check.label)}
            </Typography>
          )}
        />
      ))
    ) : (
      <span>Create some tags</span>
    )
  ) : (
    <ComponentLoader />
  );
};

export default PostTagCheckbox;
