/* eslint-disable react/no-danger */
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container, Grid, Typography, Chip, 
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import dayjs from 'dayjs';
import { PublicBlogContext, PublicBlogProvider } from './context';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import PostSingleCard from './components/PostSingleCard';
import ComponentLoader from '../../../components/ComponentLoader';
import { useAuthentication } from '../../../utils/store';

const ShowPublicPostPage = () => {
  const { postSlug } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthentication();
  console.log(user.Role);
  const {
    t,
    postSingle,
    postSingleLoaded,
    fetchPostSingleData,
  } = useContext(PublicBlogContext);
  
  useEffect(() => {
    fetchPostSingleData(postSlug);
  }, [fetchPostSingleData]);
  
  return (
    <Container className="blog-post-page" maxWidth="lg">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mt: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {postSingleLoaded ? postSingle?.title : null}
          </span>
        </Typography>
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: { xs: 'center', md: 'space-between' },
            paddingTop: '14px',
            gap: '12px',
          }}
         
        >
          <Grid
            item
            style={{
              padding: '6px 0', display: 'flex', justifyContent: 'center', gap: '8px', 
            }}
          >
            <Button size="sm" onClick={() => navigate('/blog')} bordered>
              <span>{t('Go Back')}</span>
            </Button>
            { user.Role === 'admin' && (
            <Button size="sm" onClick={() => navigate(`/admin/b/post/${postSlug}`)} bordered>
              <span>{t('Edit')}</span>
            </Button>
            )}
           
          </Grid>
          <Grid item style={{ padding: '6px 0' }}>
            {postSingleLoaded && <Chip label={postSingle?.category} size="medium" variant="outlined" />}
          </Grid>
          <Grid
            item
            style={{
              padding: '6px 0', display: 'flex', justifyContent: 'center', gap: '8px', flexWrap: 'wrap', alignItems: 'center', 
            }}
          >
            {postSingleLoaded && postSingle?.tags ? postSingle?.tags.map((tag, index) => (
             
              <Chip key={`${tag}-${index}`} label={tag.title} variant="filled" color="secondary" size="small" />
             
            )) : null}
          </Grid>
         
        </Grid>

        <Grid style={{ padding: '0px 0' }}>
          <div className="divider" />
        </Grid>
        
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: { xs: 'center', md: 'flex-start' },
            justifyContent: 'space-between',
            paddingBottom: '20px',
            gap: '12px',
          }}
         
        >
          <Grid item style={{ padding: '0px 0' }}>
            {postSingleLoaded && (
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 600,
                mt: 1,
                width: '100%',
              }}
            >
              <span className="primary-text-gradient">
                {postSingleLoaded ? `Last updated on ${dayjs(postSingle?.updatedAt).tz('UTC').format('MMM DD, YYYY')}` : null}
              </span>
            </Typography>
            )}
          </Grid>
          <Grid item style={{ padding: '0px 0' }}>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '14px',
                fontWeight: 600,
                mt: 1,
                width: '100%',
              }}
            >
              <span className="primary-text-gradient">
                {postSingleLoaded ? dayjs(postSingle?.createdAt).tz('UTC').format('MMM DD, YYYY') : null}
              </span>
            </Typography>
          </Grid>
         
        </Grid>
        {postSingleLoaded && postSingle && postSingle.imageUrl 
          ? (
            <CardMedia
              sx={{ height: 400, borderRadius: '12px' }}
              image={postSingle.imageUrl}
              title={postSingle.title}
            />
          )
          : null }
        {postSingleLoaded && (
          <Grid style={{ padding: '24px 0' }}>
            <div className="richContent" dangerouslySetInnerHTML={{ __html: postSingle.content }} />
          </Grid>
          
        ) }
        
        <Grid container spacing={3}>
  
          {postSingleLoaded && postSingle && postSingle?.related.length > 3 ? (
            <>
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontSize: '26px',
                  fontWeight: 600,
                  mt: 1,
                  width: '100%',
                }}
              >
                <span className="primary-text-gradient">
                  {t('Related Posts')}
                </span>
              </Typography>
              {postSingle?.related.map((post) => (
                <Grid item xs={12} md={6} xl={4} key={post.id} display="flex">
                  <PostSingleCard post={post} />
                </Grid>
              ))}
            </>
          ) 
            : !postSingleLoaded ? <ComponentLoader /> : null}
          
        </Grid>
       
      </Layout>
    </Container>
  );
};

export default () => (
  <PublicBlogProvider>
    <ShowPublicPostPage />
  </PublicBlogProvider>
);
