import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Grid, Tooltip } from '@mui/material';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/Inputs/TextInput';
import Modal from '../../../../components/Modal';
import { PendingRedemptionsContext } from '../context';

const PayRedemptionModal = () => {
  const [memeCoinsAmount, setMemeCoinsAmount] = useState('');

  const {
    redeemDestinationData,
    submittingRedeemModal,
    handlePayRedeemSubmitModal,
    handlePayRedeemCloseModal,
  } = useContext(PendingRedemptionsContext);

  useEffect(() => {
    setMemeCoinsAmount('');
  }, [redeemDestinationData]);

  useEffect(() => {
    if (memeCoinsAmount === 0) {
      setMemeCoinsAmount('');
    }
  }, [memeCoinsAmount]);

  return (
    <Modal
      title="Mark as Paid"
      width="550px"
      open={!!redeemDestinationData}
      handleClose={handlePayRedeemCloseModal}
    >
      <Grid item className="flex" justifyContent="center" position="relative" top="-20px" mb={1}>
        <span className="primary-text-gradient">{redeemDestinationData?.user}</span>
      </Grid>
      <Tooltip placement="top" title="Click to Copy">
        <button
          type="button"
          className="text-center mb-4"
          style={{
            display: 'block',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            color: 'white',
            margin: '0 auto 24px',
            fontWeight: '600',
          }}
          onClick={async () => {
            await navigator.clipboard.writeText(
              redeemDestinationData?.destination,
            );
            toast.success('Wallet Key copied!');
          }}
        >
          {redeemDestinationData?.destination}
        </button>
      </Tooltip>
      <TextInput
        type="number"
        value={memeCoinsAmount}
        onChange={(e) => {
          setMemeCoinsAmount(e);
        }}
        placeholder="Meme Coins Amount"
        className="mb-5"
      />
      <Button
        onClick={() => {
          if (!submittingRedeemModal && !!memeCoinsAmount) {
            handlePayRedeemSubmitModal(memeCoinsAmount);
          }
        }}
        className="mb-5"
        disabled={submittingRedeemModal || !memeCoinsAmount}
      >
        <span className="no-wrap">Paid</span>
      </Button>
    </Modal>
  );
};

export default PayRedemptionModal;
