import { Grid, Pagination, Typography } from '@mui/material';
import dayjs from 'dayjs';
import Layout from '../../../../components/CardLayout';
import AppLoader from '../../../../components/AppLoader';
import useMyCoinPicks from '../../context/hook';
import Price from '../../../../components/Price';

export default function GridView() {
  const {
    loading, rows, paginationData, getMyPicks,
  } = useMyCoinPicks();

  return (
    <>
      {loading && <AppLoader overpage />}
      <h1
        style={{
          fontWeight: 600,
          fontSize: '28px',
          textAlign: 'center',
          marginTop: '32px',
        }}
      >
        <span className="primary-text-gradient">My Coin Picks</span>
      </h1>
      <Layout className="my-coin-picks-list-view" mobileClassName="my-coin-picks-list-view" maxWidth="xl" cardMainClassName="pt-10 pl-0 pr-0" size="xsm">
        <Grid container spacing={4} padding={0}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {rows && rows.map((row) => (
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={row.id}
                className="my-coin-picks-list-item"
              >
                <Grid item xs={2} className="coin-image">
                  <img src={row?.image} alt={row?.choosenCoin} />
                </Grid>

                <Grid item xs={5} className="list-items">
                  <Grid item className="game-price">
                    <Typography>
                      {`${row?.price} MG Coins Game`}
                    </Typography>
                  </Grid>
                  <Grid item className="coin-name">
                    <Typography>{row?.choosenCoin}</Typography>
                  </Grid>
                </Grid>

                <Grid item xs={5} className="list-items-right">
                  <Grid item className="coin-price">
                    <Typography>
                      <span style={{ fontSize: '11px', color: '#fff' }}>{'Entry: '}</span>
                      <Price value={row?.entryPrice} dollarSign />
                    </Typography>
                  </Grid>
                  <Grid item className="coin-pick-time">
                    <Typography>
                      {`${dayjs(row?.lastVotedAt).utc().format('MMM DD, YYYY HH:mm')} GMT`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {paginationData && (
              <div className="flex justify-end mt-4">
                <Typography sx={{ mt: 0.5 }}>
                  {`Total of: ${paginationData.totalItems} votes`}
                </Typography>
                <Pagination
                  size="small"
                  siblingCount={0}
                  count={paginationData?.total}
                  page={paginationData?.page}
                  onChange={(val, page) => {
                    getMyPicks(page);
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
}
