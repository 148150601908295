import dayjs from 'dayjs';
import { renderNumber } from '../../utils/string';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',

  cellClassName: () => 'first-column',
  renderCell: ({ value }) => (!Number.isNaN(value) ? value.toLocaleString('en-us') : value),
};

export const gamercolumnsConfig = (t) => [
  {
    field: 'value',
    headerName: t('AMOUNT'),
    minWidth: 120,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <span className={row.direction}>{row.value.toLocaleString('en-us')}</span>
    ),
  },
  {
    field: 'type',
    headerName: t('Type'),
    minWidth: 130,
    ...defaultConfig,
    renderCell: ({ value }) => (value === 'CoinPacks' ? 'Tokens' : value),
  },
  {
    field: 'description',
    minWidth: 500,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    flex: 1,
    renderCell: ({ value }) => t(`${value}`),
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 250,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('lll'),
  },
];

export const ambassadorcolumnsConfig = (t) => [
  {
    field: 'amount',
    headerName: t('AMOUNT'),
    minWidth: 270,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <span className={row.direction}>
        {`$${!Number.isNaN(row.value) ? row.amount.toLocaleString('en-us') : row.amount}`}
      </span>
    ),
  },
  {
    field: 'description',
    minWidth: 630,
    headerName: `${t('DESCRIPTION')}`,
    flex: 1,
    ...defaultConfig,
    renderCell: ({ value }) => t(`${value}`),
  },
  {
    field: 'actionForDate',
    headerName: t('DATE'),
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ row }) => dayjs(row?.actionForDate || row.createdAt).tz('GMT').format('MMM DD, YYYY'),
  },
];

export const transferscolumnsConfig = (t) => [
  {
    field: 'token',
    headerName: t('Token RECEIVED / DEDUCTED'),
    minWidth: 270,
    ...defaultConfig,
  },
  {
    field: 'description',
    minWidth: 630,
    headerName: `${t('DESCRIPTION')}`,
    ...defaultConfig,
    renderCell: ({ value }) => t(`${value}`),
  },
  {
    field: 'createdAt',
    headerName: t('DATE'),
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('lll'),
  },
];

export const potentialbonusescolumnsConfig = (t) => [
  {
    field: 'Username',
    headerName: t('Username'),
    flex: 1,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <span>
        {row.Username}
      </span>
    ),
  },

  {
    field: 'votes',
    flex: 1,
    headerName: `${t('TOTAL GAMES')}`,
    ...defaultConfig,
    renderCell: ({ value }) => `${value}/3`,
  },
  {
    field: 'id',
    headerName: t('FULL NAME'),
    flex: 1,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <span>
        {`${row.Name} ${row.Surname}`}
      </span>
    ),
  },
];

export const modalTypes = {
  WITHDRAW: 'withdraw',
  TRANSFER: 'transfer',
  CONFIRMSTION: 'confirmation',
  COMMITMENT: 'commitment',
};

export const withdrawFormConfig = [
  {
    name: 'amount',
    type: 'number',
    label: 'Amount (BTC)',
  },
  {
    name: 'wallet',
    type: 'text',
    label: 'BTC Wallet',
  },
];

export const transferFormConfig = [
  {
    name: 'tokens',
    label: 'Tokens',
    type: 'number',
  },
  {
    name: 'userId',
    label: 'User',
    type: 'autocomplete',
    endPoint: '/users?isProduct=true', // TODO: add endpoint
    mapper: (val) => ({ label: `${val.Name} ${val.Surname} (${val.Username})`, value: val }), // TODO: add mapper
  },
];

export const withdrawFormValidationConfig = {
  amount: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
  wallet: { isEmpty: false, type: 'String', message: 'Please do not leave this field empty!' },
};

export const earningsColumnsConfig = (t) => [
  {
    field: 'year',
    headerName: t('Month'),
    minWidth: 180,
    flex: 1,
    ...defaultConfig,
    renderCell: ({ row }) => `${row.year}-${row.month}`,
  },
  {
    field: 'amount',
    minWidth: 180,
    headerName: `${t('Amount')}`,
    ...defaultConfig,
    renderCell: ({ value }) => `$${renderNumber(value)}`,
  },
  {
    field: 'transactions',
    headerName: t('Transactions Count'),
    minWidth: 200,
    ...defaultConfig,
  },
];

export default {};
