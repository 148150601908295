import { createPortal } from 'react-dom';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CloseButtonIcon from '@mui/icons-material/Close';
import QRCodeStyling from 'qr-code-styling';
import {
  useEffect, useRef, useState, 
} from 'react';
import Button from '../Button';

const QRCodeModal = ({ url, downloadText }) => {
  const qrCode = new QRCodeStyling({
    width: 400,
    height: 400,
    type: 'svg',
    image: '/assets/logo_sol.png',
    data: url,
    dotsOptions: {
      type: 'rounded',
      background: 'black',
      gradient: {
        type: 'linear',
        rotation: 0,
        colorStops: [
          {
            offset: 0,
            color: '#78e4ff',
          },
          {
            offset: 1,
            color: '#DE85FF',
          },
        ],
      },
    },
    backgroundOptions: {
      color: '#121316',
    },
    imageOptions: {
      crossOrigin: 'anonymous',
      margin: 20,
    },
  });
  
  const [showModal, setShowModal] = useState(false);
  const qrContainerRef = useRef(null);
  const backdropRef = useRef(null);

  const handleOpenButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDownloadButtonClick = () => {
    qrCode.download({ extension: 'png', name: downloadText });
  };

  useEffect(() => {
    if (qrContainerRef.current) {
      qrContainerRef.current.innerHtml = '';
      qrCode.append(qrContainerRef.current);
    }
  }, [qrContainerRef.current, showModal]);

  return (
    <>
      <QrCodeScannerIcon
        sx={{ cursor: 'pointer' }}
        color="primary"
        onClick={handleOpenButtonClick}
      />
      {showModal
        && createPortal(
          <div className="qr-code-modal">
            <div
              className="backdrop"
              ref={backdropRef}
              onClick={handleCloseModal}
              role="button"
              aria-label="backdrop"
              aria-hidden="true"
              tabIndex={0}
            />
            <div className="container">
              <CloseButtonIcon
                onClick={handleCloseModal}
                sx={{ cursor: 'pointer' }}
                color="primary"
                className="qr-close-button"
              >
                Close
              </CloseButtonIcon>

              <div ref={qrContainerRef} />

              {/* <Button
                onClick={handleDownloadButtonClick}
                size="sm"
                type="button"
                className="qr-download-button"
              >
                Download
              </Button> */}
            </div>
          </div>,
          document.body,
        )}
    </>
  );
};

export default QRCodeModal;
