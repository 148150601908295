/* eslint-disable react/no-danger */
import {
  useCallback, useContext, useEffect, useState, 
} from 'react';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../utils/store';
import useApi from '../../utils/api';
import Modal from '../Modal';
import Button from '../Button';
import { AppContext } from '../../utils/store/app';

const GlobalNotifyModal = () => {
  const { user } = useAuthentication();
  const { authGet, authPost } = useApi();
  const navigate = useNavigate();
  const [hideModal, setHideModal] = useState(true);
  const [notifyMessages, setNotifyMessages] = useState([]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const { fetchGlobalNotificationsCount } = useContext(AppContext);

  const fetchUserMessages = useCallback(async () => {
    const currentUrl = window.location.pathname;

    if (currentUrl === '/notifications') {
      return;
    }

    const response = await authGet('global-notify/unread');

    setNotifyMessages(response.data);
    setMaxIndex(response.data.length - 1);
  }, [authGet]);

  const markAllMessage = useCallback(() => {
    const notifyIds = notifyMessages.map((notify) => notify.id);
    
    authPost('global-notify/complete', { data: notifyIds });

    fetchGlobalNotificationsCount();
  }, [authPost, notifyMessages, fetchGlobalNotificationsCount]);

  const handleNextButtonClick = useCallback(async () => {
    if (currentMessageIndex === maxIndex) {
      markAllMessage();
      setHideModal(true);
    }

    if (currentMessageIndex < maxIndex) {
      setCurrentMessageIndex((prev) => prev + 1);
    }
  }, [authPost, markAllMessage, currentMessageIndex, maxIndex]);

  const handleOpenNotificationsPage = useCallback(async () => {
    navigate('/notifications');
    setHideModal(true);
  }, []);

  const handleCloseModal = useCallback(() => { setHideModal(true); }, [user]);

  useEffect(() => {
    fetchUserMessages();
  }, [user]);

  useEffect(() => {
    if (notifyMessages?.length > 0) {
      setHideModal(false);
    }
  }, [notifyMessages]);

  return (
    !hideModal && (
      <Modal
        open={!hideModal && notifyMessages?.length > 0}
        title="System Notifications"
        handleClose={() => handleCloseModal()}
        width="550px"
      >
        {maxIndex > 0 && (
          <Typography
            textAlign="center"
            position="relative"
            top="-18px"
            fontWeight={600}
          >
            <span className="primary-text-gradient">
              {`Message ${currentMessageIndex + 1} of ${maxIndex + 1}`}
            </span>
          </Typography>
        )}
        <div className="global-notify-container">
          {notifyMessages.map((notify, idx) => (
            <div
              key={idx}
              className={`global-notify-item${
                idx === currentMessageIndex ? ' current' : ''
              }`}
            >
              <Typography
                textAlign="center"
                fontWeight={600}
                marginY={2}
                fontSize="16px"
              >
                {notify.title}
              </Typography>
              <span
                style={{ textAlign: 'center', fontSize: '16px' }}
                dangerouslySetInnerHTML={{ __html: notify.message }}
              />
            </div>
          ))}
        </div>

        <Grid
          item
          xs={12}
          display="flex"
          sx={{
            justifyContent: { xs: 'center', md: 'flex-end' },
            flexDirection: { xs: 'column-reverse', md: 'row' },
            alignItems: 'center',
            gap: { xs: '16px', md: '20px' },
            marginTop: '32px',
          }}
        >
          {currentMessageIndex < maxIndex && (
            <Button
              onClick={handleOpenNotificationsPage}
              size="sm"
              type="button"
              style={{ width: '100%', padding: '12px', fontSize: '14px' }}
              bordered
            >
              Go to Notifications
            </Button>
          )}
          <Button
            onClick={handleNextButtonClick}
            size="sm"
            type="button"
            style={{ width: '100%', padding: '12px', fontSize: '14px' }}
          >
            {currentMessageIndex < maxIndex ? 'Next' : 'All done'}
          </Button>
        </Grid>
      </Modal>
    )
  );
};

export default GlobalNotifyModal;
