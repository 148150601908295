import {
  useCallback, useEffect, useState, useContext, 
} from 'react';
import useMembershipsApi from './api';
import { AuthenticationContext } from '../../utils/store/authentication';

export default function useMemberships() {
  const {
    getPassess,
    getTransfers,
    passTypes,
    getPurchaseHistory,

    solanaPaymentConfirmedStatus,
    validateSolanaPaymentPass,
  } = useMembershipsApi();
  
  const { user } = useContext(AuthenticationContext);
  const [passes, setPasses] = useState([]);
  const [passesPage, setPassesPage] = useState(1);
  const [passesTotal, setPassesTotal] = useState(0);
  const [passesTotalAvailable, setPassesTotalAvailable] = useState(0);
  const [passesLoading, setPassesLoading] = useState(false);

  const [transfers, setTransfers] = useState([]);
  const [transferesPage, setTransferesPage] = useState(1);
  const [transferesTotal, setTransferesTotal] = useState(0);
  const [transfersLoading, setTransfersLoading] = useState(false);

  const [purchaseHistoryLoading, setPurchaseHistoryLoading] = useState(false);
  const [PurchaseHistoryPage, setPurchaseHistoryPage] = useState(1);
  const [purchaseHistoryTotal, setPurchaseHistoryTotal] = useState(0);
  const [purchaseHistory, setPurchaseHistory] = useState([]);

  const [tabsValue, setTabsValue] = useState(0);
  const [tabsHistoryValue, setTabsHistoryValue] = useState(0);

  const handleTabChange = (_, newValue) => {
    setTabsValue(newValue);
  };

  const handleTabHistoryChange = (_, newValue) => {
    setTabsHistoryValue(newValue);
  };

  const fetchPasses = useCallback(
    async (filters = { page: 1 }) => {
      setPassesLoading(true);
      try {
        setPassesPage(filters.page);
        const res = await getPassess(filters);
        setPassesTotal(res.Total);
        setPassesTotalAvailable(res.TotalAvailable);
        setPasses(res.Items.map((v) => ({ ...v, id: v._id })));
      } catch (err) {
        console.log(err);
      }
      setPassesLoading(false);
    },
    [getPassess],
  );

  const fetchTransfers = useCallback(
    async (filters = { page: 1 }) => {
      setTransfersLoading(true);
      try {
        setTransferesPage(filters.page);
        const res = await getTransfers(filters);
        setTransferesTotal(res.total);
        setTransfers(res.data);
      } catch (err) {
        console.log(err);
      }
      setTransfersLoading(false);
    },
    [getTransfers],
  );

  const fetchPurchaseHistory = useCallback(
    async (filters = { page: 1 }) => {
      setPurchaseHistoryLoading(true);
      try {
        setPurchaseHistoryPage(filters.page);
        const res = await getPurchaseHistory(filters);
        setPurchaseHistoryTotal(res.total);
        setPurchaseHistory(res.data);
      } catch (err) {
        console.log(err);
      }
      setPurchaseHistoryLoading(false);
    },
    [getPurchaseHistory],
  );

  const refresh = useCallback(() => {
    fetchPasses();
    fetchTransfers();
    fetchPurchaseHistory();
  }, [fetchPasses, fetchTransfers, fetchPurchaseHistory]);

  useEffect(() => {
    fetchPasses();
    fetchTransfers();
    fetchPurchaseHistory();
  }, []);

  return {
    user,
    passTypes,

    passes,
    fetchPasses,
    passesTotal,
    passesTotalAvailable,
    passesPage,
    passesLoading,
    setPassesLoading,

    transfers,
    setTransfersLoading,
    transfersLoading,
    fetchTransfers,
    transferesPage,
    transferesTotal,

    tabsValue,
    setTabsValue,
    handleTabChange,
    refresh,

    handleTabHistoryChange,
    tabsHistoryValue,
    setTabsHistoryValue,

    purchaseHistoryLoading,
    PurchaseHistoryPage,
    purchaseHistoryTotal,
    purchaseHistory,
    fetchPurchaseHistory,
    setPurchaseHistoryLoading,

    solanaPaymentConfirmedStatus,
    validateSolanaPaymentPass,
  };
}
