import { Container, Grid, Typography } from '@mui/material';
import Layout from '../../components/CardLayout';
import Icon from '../../components/Icon';
import AffiliateLink from '../../components/AffiliateLink';

export default function CompPlan() {
  return (
    <Container sx={{ mt: '40px' }} maxWidth="xl">
      <Layout maxWidth="full" className="mb-10">
        <Grid container justifyContent="flex-end">
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '26px',
              fontWeight: 600,
              mt: 4,
              mb: 8,
              width: '100%',
            }}
          >
            <span className="primary-text-gradient">
              Legacy Compensation Plan
            </span>
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item xs={12} lg={7} xl={6}><AffiliateLink /></Grid>
        </Grid>
      </Layout>
      <Grid
        xl={12}
        lg={12}
        md={12}
        xs={12}
        sm={12}
        item
        sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
      >
        <Layout
          className="flex-1"
          maxWidth="100%"
          cardMainClassName="h-full flex flex-column"
        >
          <div className="comp-plan">
            <div className="row">
              <div className="col big">
                <div className="title">
                  <h3>Founder</h3>
                </div>
                <div className="infos">
                  <div className="info">
                    <p>*100 Personal Volume</p>
                    <p>*3 Personal Sponsored Active Ambassador</p>
                  </div>
                  <div className="info">
                    <p>Daily Pay - $5</p>
                    <p>Yearly Earning - $1,825</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col br">
                <div className="title">
                  <h3>VIP Founder</h3>
                  <p>10,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $10</p>
                    <p>Yearly Earning - $3,650</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="title">
                  <h3>Elite Founder</h3>
                  <p>25,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $25</p>
                    <p>Yearly Earning - $9,125</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col br">
                <div className="title">
                  <h3>1 Star Founder</h3>
                  <p>50,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $50</p>
                    <p>Yearly Earning - $18,250</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="title">
                  <h3>2 Star Founder</h3>
                  <p>100,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $100</p>
                    <p>Yearly Earning - $36,500</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col br">
                <div className="title">
                  <h3>3 Star Founder</h3>
                  <p>150,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $150</p>
                    <p>Yearly Earning - $54,750</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="title">
                  <h3>4 Star Founder</h3>
                  <p>200,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $200</p>
                    <p>Yearly Earning - $73,000</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col br">
                <div className="title">
                  <h3>5 Star Founder</h3>
                  <p>300,000 Team Volume</p>
                </div>

                <div className="infos">
                  <div className="info">
                    <p>Daily Pay - $300</p>
                    <p>Yearly Earning - $109,500</p>
                  </div>
                </div>
              </div>

              <div className="col">
                <div className="infos">
                  <div className="info sm-info flex-1">
                    <div className="flex">
                      <div className="mr-2 mt-1">
                        <Icon
                          name="Star"
                          width={16}
                          height={16}
                          color="#78e4ff"
                        />
                      </div>
                      <div className="flex flex-column justify-center">
                        <p>Active = $100 personal volume</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="mr-2 mt-1">
                        <Icon
                          name="Star"
                          width={16}
                          height={16}
                          color="#78e4ff"
                        />
                      </div>
                      <div className="flex flex-column justify-center">
                        <p>
                          Qualified Ambassador = 3 personally sponsored active
                          ambassadors
                          {' '}
                        </p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="mr-2 mt-1">
                        <Icon
                          name="Star"
                          width={16}
                          height={16}
                          color="#78e4ff"
                        />
                      </div>
                      <div className="flex flex-column justify-center">
                        <p>
                          The first $100 personal volume converts to team volume
                          to upline sponsor
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col big last">
                <div className="title">
                  <h3>Director</h3>
                </div>
                <div className="infos">
                  <div className="info">
                    <p>600,000</p>
                    <p>Team Volume</p>
                  </div>
                  <div className="info">
                    <p>Daily Pay - $500</p>
                    <p>Yearly Earning - $182,500</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col big last">
                <div className="title">
                  <h3>Gold Director</h3>
                </div>
                <div className="infos">
                  <div className="info">
                    <p>900,000</p>
                    <p>Team Volume</p>
                  </div>
                  <div className="info">
                    <p>Daily Pay - $750</p>
                    <p>Yearly Earning - $273,750</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col big last">
                <div className="title">
                  <h3>Platinum Director</h3>
                </div>
                <div className="infos">
                  <div className="info">
                    <p>1.5 million</p>
                    <p>Team Volume</p>
                  </div>
                  <div className="info">
                    <p>Daily Pay - $1,000</p>
                    <p>Yearly Earning - $365,000</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col big last">
                <div className="title">
                  <h3>Diamond Director</h3>
                </div>
                <div className="infos">
                  <div className="info">
                    <p>2.1 million</p>
                    <p>Team Volume</p>
                  </div>
                  <div className="info">
                    <p>Daily Pay - $2,000</p>
                    <p>Yearly Earning - 730,000</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col big last">
                <div className="title">
                  <h3>Presidential Director</h3>
                </div>
                <div className="infos">
                  <div className="info">
                    <p>3 million</p>
                    <p>Team Volume</p>
                  </div>
                  <div className="info">
                    <p>Daily Pay - $3,000</p>
                    <p>Yearly Earning - $1,095,000</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <div className="row-footer">
                  <p className="primary mb-2">
                    Founder Team Balance: As a founder, TV is calculated 50/50
                    for each team, with the smaller team being multiplied by two
                    to determine total volume.
                  </p>
                  <p className="primary mb-4">
                    Director Team Balance: As a Director, TV is calculated
                    33/33/33 for each team, with the smallest team being
                    multiplied by three to determine total volume.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Grid>
    </Container>
  );
}
