import { Container, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../components/CardLayout';
import Button from '../../../components/Button';
import { AdminBlogContext, AdminBlogProvider } from './context';
import Form from '../../../components/Form';
import { blogPostFormConfig, blogPostFormDefaultValues } from './config';
import PostTagCheckbox from './components/PostTag/PostTagCheckbox';

const AdminBlogAddPostPage = () => {
  const navigate = useNavigate();
  
  const {
    t,
    handleAddEditPostFormSubmit,
    postFormSubmitting,
    postFormErrors,
    setPostFormErrors,
  } = useContext(AdminBlogContext);

  return (
    <Container className="admin-blog-page add" maxWidth="xl">
      <Layout
        maxWidth="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '26px',
            fontWeight: 600,
            mb: 1,
            width: '100%',
          }}
        >
          <span className="primary-text-gradient">
            {t('Create Post')}
          </span>
        </Typography>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '14px',
            gap: '24px',
          }}
        >
          <Button size="sm" onClick={() => navigate('/admin/b/posts')} bordered>
            <span>{t('Go Back')}</span>
          </Button>
        </Grid>

        <Grid item xs={12} mt={6}>
          <div className="divider" />
        </Grid>

        <Grid container pt={2} mb={4} flexDirection={{ xs: 'column-reverse', lg: 'row' }}>
          <Grid item xs={12} lg={9} paddingRight={{ lg: 4 }}>
            <Form
              size="md"
              defaultFormValue={blogPostFormDefaultValues}
              config={blogPostFormConfig}
              onSubmit={handleAddEditPostFormSubmit}
              errors={postFormErrors}
              setErrors={setPostFormErrors}
              buttonType="button"
              buttonStyle={{ width: '100%', marginTop: 0 }}
              submitLabel={t('Save')}
              formLoading={postFormSubmitting}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            gap={4}
            display="flex"
            flexDirection="column"
            sx={{ marginY: { xs: '32px', lg: '0' } }}
          >
            <div className="relations">
              <Typography
                fontSize="16px"
                fontWeight={600}
              >
                <span>Select Tags</span>
              </Typography>
              <div className="divider" />
              <PostTagCheckbox />
            </div>
          </Grid>
        </Grid>
      </Layout>
    </Container>
  );
};

export default () => (
  <AdminBlogProvider>
    <AdminBlogAddPostPage />
  </AdminBlogProvider>
);
