import { Grid, Typography } from '@mui/material';
import { useEffect, useContext } from 'react';
import Table from '../../../components/Table';
import { potentialbonusescolumnsConfig } from '../config';
import { Context } from '../context';
import Layout from '../../../components/CardLayout';

const Potentialbonuses = () => { 
  const {
    fetchPotentialbonuses,
    potentialbonuses,
    potentialbonusesLoaded, 
    t,
  } = useContext(Context);
  
  useEffect(() => {
    fetchPotentialbonuses();
  }, []);
    
  if (potentialbonuses && potentialbonuses.length === 0) {
    return null;
  }
  
  return (
    <Layout maxWidth="xl">
      <Grid
        container
        paddingY="12px"
        borderBottom="0px solid rgba(255,255,255, 0.1)"
      >
        <Typography
          className="flex-1"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: { xs: '18px', md: '26px' },
            fontWeight: 600,
            mb: 2,
          }}
        >
          {t('Potential Match Bonuses')}
        </Typography>
        <Table
          loading={!potentialbonusesLoaded}
          columns={potentialbonusescolumnsConfig(t)}
          rows={potentialbonuses}
          className="table mb-4"
        />
      </Grid>
    </Layout>
  ); 
};

export default Potentialbonuses;
