import dayjs from 'dayjs';
import { IconButton, Tooltip } from '@mui/material';
import Icon from '../../../components/Icon';

export const defaultFormValues = {};

const defaultColConfig = {
  sortable: false,
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  cellClassName: (params) => {
    if (params.field === 'rank') {
      return 'first-column ';
    }
    return '';
  },
};

export const defaultRedeemColumnsConfig = (t, setRedeemDestinationData) => [
  {
    field: 'name',
    headerName: t('User'),
    flex: 1,
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => `${row.name} ${row.surname} (${row.userId}) - ${row.username}`,
  },
  {
    field: 'memecoin',
    headerName: t('Meme Coin'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ value }) => value.name,
  },
  {
    field: 'createdAt',
    headerName: t('Redeem Date'),
    minWidth: 150,
    ...defaultColConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('MMM DD, YYYY'),
  },
  {
    field: 'solanaWalletKey',
    headerName: t('Perform Payment'),
    minWidth: 200,
    ...defaultColConfig,
    renderCell: ({ row }) => (
      <Tooltip placement="top" title="Perform Payment">
        <IconButton
          color="inherit"
          onClick={() => setRedeemDestinationData({
            redeemId: row.id,
            destination: row.solanaWalletKey,
            user: `${row.name} ${row.surname} (${row.userId}) - ${row.username}`,
          })}
        >
          <Icon name="MoneyHand" width={24} height={24} />
        </IconButton>
      </Tooltip>
    ),
  },
];
