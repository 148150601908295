import {
  useEffect, useMemo, useState, useCallback, 
} from 'react';
import { createTheme } from '@mui/material';
import { useGeneral } from '..';

/**
 * Hook where StyleContext functionallity is created
 * @returns {Object}  StyleContext variables
 */
export default function useStyleStore() {
  const { theme } = useGeneral();
  const [width, setWidth] = useState(0);
  const isMobile = useMemo(() => width <= 850, [width]);
  const isTablet = useMemo(() => width <= 1200, [width]);
  const isDesktop = useMemo(() => width > 1200, [width]);
  
  const setWidthCallback = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  // Theme used for MaterialUI
  const muiTheme = useMemo(
    () => createTheme({
      palette: {
        primary: {
          main: theme.PRIMARY_COLOR,
        },
        secondary: {
          main: theme.SECONDARY_COLOR,
        },
        background: {
          paper: theme.PAPER,
          default: theme.BACKGROUND_COLOR,
          black: theme.BLACK,
        },
        mode: theme.MODE,
      },
      mixins: {
        toolbar: {
          minHeight: 80,
        },
      },
      typography: {
        fontFamily: 'Twemoji Country Flags, Montserrat',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
      },
    }),
    [theme],
  );
  
  useEffect(() => {
    setWidthCallback();
    window.addEventListener('resize', setWidthCallback);
    return () => window.removeEventListener('resize', setWidthCallback);
  }, []);
  
  useEffect(() => {
    // Change CSS variables
    const root = document.documentElement;

    Object.keys(theme).forEach((property) => {
      if (property === 'name') {
        return;
      }

      root.style.setProperty(
        `--${property.toLocaleLowerCase().replace('_', '-')}`,
        theme[property],
      );
    });
  }, [theme]);

  return {
    muiTheme, isMobile, isTablet, isDesktop, 
  };
}
