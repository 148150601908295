import dayjs from 'dayjs';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

const defaultConfig = {
  headerClassName: 'super-app-theme--header',
  headerAlign: 'left',
  sortable: true,
  renderCell: ({ value }) => value,
};

export const columnsConfig = (t, handleOpenReadNotificationModal) => [
  {
    field: 'title',
    headerName: t('Message Title'),
    minWidth: 250,
    flex: 1,
    ...defaultConfig,
    renderCell: ({ row }) => (
      <button
        name="ReadMessage"
        type="button"
        onClick={() => handleOpenReadNotificationModal(row)}
        className={`read-notification-button${row.unread ? ' unread' : ''}`}
      >
        {row.title}
      </button>
    ),
  },
  {
    field: 'createdAt',
    headerName: '',
    minWidth: 200,
    ...defaultConfig,
    renderCell: ({ value }) => dayjs(value).tz('GMT').format('lll'),
  },
  {
    field: 'unread',
    headerName: '',
    minWidth: 100,
    ...defaultConfig,
    renderCell: ({ value }) => ((value) ? (
      <MarkEmailUnreadIcon style={{ color: 'var(--primary-color)' }} />
    ) : (
      <MailOutlineIcon style={{ opacity: '0.4' }} />
    )),
  },
];
