import { useContext, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { AdminBlogContext } from '../../context';
import Button from '../../../../../components/Button';
import TextInput from '../../../../../components/Inputs/TextInput';
import Modal from '../../../../../components/Modal';
import ComponentLoader from '../../../../../components/ComponentLoader';

const PostTagModal = () => {
  const {
    t,
    fetchPostTagsData,
    postTags,
    postTagsLoaded,
    openPostTagsModal,
    setOpenPostTagsModal,
    postTag,
    setPostTag,
    handleAddPostTagSubmit,
    handleOpenPostTagDeleteModal,
  } = useContext(AdminBlogContext);

  useEffect(() => {
    fetchPostTagsData();
  }, []);

  return (
    <Modal
      title={t('Post Tags')}
      open={openPostTagsModal}
      handleClose={() => setOpenPostTagsModal(false)}
      width="600px"
    >
      <TextInput
        className="mb-4"
        value={postTag.title}
        onChange={(e) => {
          setPostTag({ id: postTag.id, title: e });
          if (!(e.length > 0)) {
            setPostTag({ id: '', title: '' });
          }
        }}
        endAdornment={(
          <div className="flex items-center">
            <Button
              onClick={() => {
                if (postTag?.title.length > 0 && postTagsLoaded) {
                  handleAddPostTagSubmit(postTag);
                }
              }}
              className="py-auto my-auto mr-3"
              bordered={!(postTag?.title.length > 0) || !postTagsLoaded}
              disabled={!(postTag?.title.length > 0) || !postTagsLoaded}
              size="sm"
            >
              <span className="no-wrap pl-2 pr-2">
                {postTag.id.length > 0 ? t('Edit') : t('Add')}
              </span>
            </Button>
          </div>
        )}
      />

      {postTagsLoaded ? (
        <Grid container className="post-tags" paddingTop={4}>
          <Grid item xs={8} md={10}>
            <Typography fontSize="16px" fontWeight="600">
              Tag
            </Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Typography fontSize="16px" fontWeight="600">
              Actions
            </Typography>
          </Grid>
          {postTags.length > 0 && postTags.map((tag, idx) => (
            <Grid container key={idx}>
              <Grid item xs={12}>
                <div className="divider" />
              </Grid>
              <Grid item xs={8} md={10}>
                <Typography fontSize="14px" paddingLeft={1}>
                  {tag.title}
                </Typography>
              </Grid>
              <Grid item xs={4} md={2} display="flex" gap={2}>
                <EditIcon
                  sx={{ cursor: 'pointer', display: 'block' }}
                  color="primary"
                  onClick={() => setPostTag({ id: tag.id, title: tag.title })}
                />
                <DeleteIcon
                  sx={{ cursor: 'pointer', display: 'block' }}
                  color="primary"
                  onClick={() => handleOpenPostTagDeleteModal({ id: tag.id, title: tag.title })}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <ComponentLoader />
      )}
    </Modal>
  );
};

export default PostTagModal;
