/* eslint-disable react/jsx-filename-extension */
/* eslint-disable prefer-destructuring */
import { useContext } from 'react';
import { AppContext } from '../../../../utils/store/app';

export const formConfig = [
  {
    name: 'passType',
    type: 'radioGroup',
    template: 'cards',
    label: '',
    options: [],
    defaultValue: '',
  },
  {
    name: 'amountOfPasses',
    type: 'number',
    formGroupType: 'small',
    label: 'Number of coin packs',
    withButtons: true,
  },
];

export const getFormConfig = () => {
  const { purchasablePassTypes } = useContext(AppContext);

  const opts = purchasablePassTypes
    .filter((p) => p.active)
    .map((p) => ({ label: p, value: p.id }));

  formConfig[0].options = opts;
  formConfig[0].defaultValue = opts[0]?.value;

  return formConfig;
};

export const defaultValue = { paymentMethod: 'credit', amountOfPasses: 1 };

export default formConfig;
